<template>
  <div class="h-100 w-100 d-flex flex-column">
    <b-sidebar
      id="order-validation-list"
      :title="__('database.order.validation')"
      backdrop
      body-class="gen-list-body"
      header-class="pr-4"
      right
      width="460px"
    >
      <div class="px-3 py-2">
        <validation-list
          @click-on-source-link="handleSourceLinkClick"
        ></validation-list>
      </div>
    </b-sidebar>
    <b-sidebar
      id="order-generation-list"
      :title="__('database.order.generated_files')"
      backdrop
      body-class="gen-list-body"
      header-class="pr-4"
      right
      width="460px"
    >
      <div class="px-3 py-2">
        <generation-list></generation-list>
      </div>
    </b-sidebar>
    <b-sidebar
      id="order-file-manager"
      :title="orderNumber + ': ' + __('database.app.media')"
      backdrop
      header-class="px-4"
      right
      width="100%"
    >
      <div class="m-3">
        <order-media></order-media>
      </div>
    </b-sidebar>
    <b-sidebar
      id="order-snapshot-list"
      :title="__('database.order.order_snapshots')"
      backdrop
      body-class="gen-list-body"
      header-class="pr-4"
      right
      width="460px"
    >
      <div class="px-3 py-2">
        <snapshot-list :order-id="orderId"/>
      </div>
    </b-sidebar>
    <transition-group
      class="flex-grow-1 d-flex flex-row order-body"
      name="order-transition"
      tag="div"
    >
      <div
        v-if="showSidebar"
        :key="'order-sidebar-container-' + orderId"
        class="px-0 flex-shrink-0 border-right border-dark order-sidebar"
      >
        <order-sidebar
          :key="'order-sidebar-' + orderId"
          :current-category-index.sync="currentCategoryIndex"
          :current-unit-index.sync="currentUnitIndex"
          :order-id="orderId"
        ></order-sidebar>
      </div>
      <div
        :key="'order-bulk-' + orderId"
        class="flex-grow-1 d-flex flex-row bg-light"
      >
        <order-content-navigation
          ref="order-navigator"
          :current-category-index.sync="currentCategoryIndex"
          :current-unit-index.sync="currentUnitIndex"
          :show-sidebar.sync="showSidebar"
        ></order-content-navigation>
        <order-content
          ref="order-content"
          :current-category-index.sync="currentCategoryIndex"
          :current-unit-index.sync="currentUnitIndex"
        ></order-content>
        <b-modal
          v-model="modalCompatibility"
          :cancel-title="__('database.order.ignore')"
          :ok-title="__('database.config.update')"
          @cancel="handleCancel"
          @hide="handleClose"
          @ok="handleOk"
        >
          <form ref="form" @submit.stop.prevent="handleSubmit">
            {{ __("database.order.breaking_changes") }}
            <div v-if="hasStructuralChanges" class="text-danger">
              {{ __("tenants.modal.structural_changes") }}
            </div>
            <b-form-checkbox
              id="canUpdateOrderPatchversion"
              v-model="canUpdateOrderPatchversion"
              :state="checkBoxState"
              :unchecked-value="0"
              :value="1"
              name="canUpdateOrderPatchversion"
              required
            >
              {{ __("database.order.update_order_patchversion") }}
            </b-form-checkbox>
            <a
              v-b-toggle.view-changes
              style="text-decoration: none; cursor: pointer"
              variant="primary"
            >{{ __("database.order.view_change") }}</a
            >
            <b-collapse id="view-changes" class="mt-2">
              <b-card>
                <ul
                  v-for="(mapObject, key) in changes"
                  :key="key"
                  class="card-text"
                >
                  <li v-if="key == 'paramsAdded'">
                    {{ __("database.order.added_parameters") }}
                  </li>
                  <li v-if="key == 'paramsRemoved'">
                    {{ __("database.order.removed_parameters") }}
                  </li>
                  <li v-if="key == 'paramsUpdated'">
                    {{ __("database.order.updated_parameters") }}
                  </li>
                  <ul v-for="(canvasArray, mapKey) in mapObject" :key="mapKey">
                    <li>{{ __("database.configunits." + mapKey) }}</li>
                    <ul
                      v-for="(parameterArray, mastervariable) in canvasArray[
                        'canvas'
                      ]"
                      :key="mastervariable"
                    >
                      <li>
                        {{ translateAndJoin(parameterArray["position"]) }}
                      </li>
                      <div v-if="key == 'paramsUpdated'">
                        <ul
                          v-for="(aliasValue, aliasKey) in parameterArray[
                            'aliases'
                          ]"
                          :key="aliasKey"
                        >
                          <li>{{ aliasKey + " => " + aliasValue }}</li>
                        </ul>
                      </div>
                    </ul>
                  </ul>
                </ul>
              </b-card>
            </b-collapse>
          </form>
          <template #modal-footer="{ cancel, ok }">
            <b-button variant="secondary" @click="cancel()">
              {{ __('database.order.ignore') }}
            </b-button>
            <b-button v-if="!hasStructuralChanges" variant="primary" @click="ok()">
              {{ __('database.config.update') }}
            </b-button>
          </template>
        </b-modal>
      </div>
    </transition-group>
    <order-footer
      :channel="channel"
      :order-id="orderId"
      :user-uuid="userUuid"
    ></order-footer>
  </div>
</template>

<style>
.unit-content .card-body {
  padding-right: 2px !important;
  padding-left: 1em !important;
}

.unit-content .card-body .card {
  margin-right: 0 !important;
}
</style>

<script>
import {mapActions, mapGetters} from "vuex";
import OrderContentCategory from "./OrderContentCategory.vue";

export default {
  components: {OrderContentCategory},
  data() {
    return {
      showSidebar: true,
      busy: false,
      activeNavCat: "",
      activeNavUnit: "",
      modalCompatibility: false,
      canUpdateOrderPatchversion: 0,
      checkBoxState: null,
      currentCategoryIndex: 0,
      currentUnitIndex: 0,
      routingDone: false,
    };
  },
  props: {
    orderId: Number,
    orderNumber: String,
    channel: String,
    userUuid: String,
    notCompatible: Number,
    changes: Object,
    hasStructuralChanges: Boolean
  },
  methods: {
    ...mapActions(["loadOrder"]),
    translateAndJoin(positionArray) {
      if (Array.isArray(positionArray)) {
        return positionArray
          .map((item, index) => {
            let source =
              index === positionArray.length - 1
                ? "database.parameters"
                : "database.configunits";
            return this.__(source + "." + item);
          })
          .join(" >> ");
      } else {
        return positionArray;
      }
    },
    hideOverlay() {
      this.busy = false;
    },
    handleClose() {
      this.getOrderDetails();
    },
    handleSourceLinkClick(combi) {
      this.setCurrentUnitByLabel(combi.param.basePointer);
      this.showParameter(combi.param);
      this.scrollToCurrentUnit();
      setTimeout(() => {
        this.$root.$emit("bv::toggle::collapse", "order-validation-list");
      }, "200");
    },

    showParameter(parameter) {
      if (parameter.configNeedle.length === 0) {
        return;
      }

      // This will find the unit given by pointer path, which is separated by slashes and open it
      // in the order content navigation.
      let unitPointer = parameter.configPointer.split(".");
      let entryIndex = unitPointer[2];

      // Get the reference to the unit which is inside the order-content-category component which is inside the order-content component.
      let unitContainer =
        this.$refs["order-content"].$refs["order-content-category"].$refs[
          "unit-container"
          ][this.currentUnitIndex].$refs["unit"];
      if (unitContainer) {
        // This will wait until the unitContainer has the method editUnit and then call it. If not it will wait 100ms and try again.
        if (unitContainer.editUnit) {
          unitContainer.editUnit(entryIndex);
        } else {
          setTimeout(() => {
            this.showParameter(parameter);
          }, 100);
        }
      }
    },
    scrollToCurrentUnit() {
      this.$root.scrollUnitIntoView(
        this.cats[this.currentCategoryIndex].label,
        this.map[this.currentCategoryIndex][this.currentUnitIndex].unit_label
      );
    },
    setCurrentUnitFromHash() {
      let initCategoryLabel = "";
      let initUnitLabel = "";
      let hashUrl = window.location.hash.replace(/[^a-zA-Z0-9-_]/gi, "");

      if (hashUrl && hashUrl.includes("-")) {
        let hashUrls = hashUrl.split("-");

        initCategoryLabel = hashUrls[2];
        initUnitLabel = hashUrls[3];

        this.currentCategoryIndex = this.cats.findIndex(
          (cat) => cat.label === initCategoryLabel
        );

        this.currentUnitIndex = this.map[this.currentCategoryIndex].findIndex(
          (unit) => unit.unit_label === initUnitLabel
        );
      }
    },
    setCurrentUnitByLabel(unitLabel) {
      this.currentCategoryIndex = this.getCategoryIndexByUnitLabel(unitLabel);
      this.currentUnitIndex = this.getUnitIndexByUnitLabel(unitLabel);
    },
    getCategoryIndexByUnitLabel(unitLabel) {
      for (
        let categoryIndex = 0;
        categoryIndex < this.map.length;
        categoryIndex++
      ) {
        const category = this.map[categoryIndex];

        if (
          category.findIndex((category) => category.unit_label === unitLabel) >=
          0
        ) {
          return categoryIndex;
        }
      }
      return 0;
    },
    getUnitIndexByUnitLabel(unitLabel) {
      for (
        let categoryIndex = 0;
        categoryIndex < this.map.length;
        categoryIndex++
      ) {
        const category = this.map[categoryIndex];
        let unitIndex = category.findIndex(
          (category) => category.unit_label === unitLabel
        );

        if (unitIndex >= 0) {
          return unitIndex;
        }
      }
      return 0;
    },
    handleCancel(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleIgnore();
    },
    async handleIgnore() {
      await axios
        .get("/api/orders/" + this.orderId + "/update-ignore-status")
        .then(({data}) => {
          this.modalCompatibility = false;
          this.$bvToast.toast("Ignored this action", {
            title: this.__("database.app.updated"),
            variant: "success",
            solid: true,
          });
          this.getOrderDetails();
        });
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      await axios
        .post(
          "/api/orders/" + this.orderId + "/update-patchversion",
          this.changes
        )
        .then(({data}) => {
          this.modalCompatibility = false;
          this.$bvToast.toast("Successfully updated order", {
            title: this.__("database.app.updated"),
            variant: "success",
            solid: true,
          });
          this.getOrderDetails();
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.checkBoxState = valid;
      return valid;
    },
    async getOrderDetails() {
      const response = await this.loadOrder(this.orderId);
      if (response) {
        this.$bvToast.toast(response, {
          title: this.__("database.order.error_on_snapshot_restore"),
          variant: "error",
          solid: true,
        });
      }
    },
  },
  created() {
    if (this.changes !== null && Object.keys(this.changes['paramsUpdated']).length > 0 && this.changes['paramsAdded'].length === 0 && this.changes['paramsRemoved'].length === 0) {
      return axios
        .post(
          "/api/orders/" + this.orderId + "/update-patchversion",
          this.changes
        )
        .then(({data}) => {
          this.modalCompatibility = false;

          this.getOrderDetails();
        });
    }
    if (this.notCompatible === 1) {
      this.modalCompatibility = true;
    } else {
      this.getOrderDetails();
    }
  },
  updated() {
    // if (!this.activeNavCat) {
    //   this.initNav();
    // }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      cats: "getCats",
      map: "getMap",
    }),
  },
  watch: {
    currentUnitIndex: {
      immediate: true,
      handler() {
        if (this.map.length > 0) {
          this.scrollToCurrentUnit();
        }
      },
    },
    cats: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0 && this.map.length > 0 && !this.routingDone) {
          this.setCurrentUnitFromHash();
          this.routingDone = true;
        }
      },
    },
    map: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0 && this.cats.length > 0 && !this.routingDone) {
          this.setCurrentUnitFromHash();
          this.routingDone = true;
        }
      },
    },
  },
};
</script>
