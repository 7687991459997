<template>
  <b-modal
    :visible.sync="localShowOverwriteModal"
    @hide="$emit('close')"
    centered
    hide-footer
    id="overwriteModal"
    :title="this.__('database.order.overwrite')"
  >
   <div v-if="!multiple">
      <p>{{__('database.order.overwritemodal_text_single')}}</p>
      <p style="font-size: 12px; color: red;">{{__('database.order.delete_warning')}}</p>
      <b-button @click="overwriteFile" variant="outline-danger"
      ><i class="fas fa-arrow-circle-up"></i> {{__('database.order.overwrite')}}</b-button
      >
      <b-button @click="closeOverwriteModal">{{__('database.order.cancel')}}</b-button>
    </div>
    <div v-else>
      <p>{{__('database.order.overwritemodal_text_multi')}}</p>
      <p style="font-size: 12px; color: red;">{{__('database.order.delete_warning')}}</p>
      <b-button @click="multiOverwrite" variant="outline-danger"
      ><i class="fas fa-arrow-circle-up"></i> {{__('database.order.overwrite')}}</b-button
      >
      <b-button @click="closeOverwriteModal">{{__('database.order.cancel')}}</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    showOverwriteModal: Boolean,
    multiple: Boolean,
  },
  data() {
    return {
      localShowOverwriteModal: false,
    };
  },
  computed: {},
  methods: {
    closeOverwriteModal() {
      this.$bvModal.hide("overwriteModal");
      this.$emit("close");
    },
    overwriteFile() {
      this.$bvModal.hide("overwriteModal");
      this.$emit("file-overwrite");
    },
    multiOverwrite() {
      this.$bvModal.hide("overwriteModal");
      this.$emit("multi-overwrite");
    }
  },
  watch: {
    showOverwriteModal(newValue) {
      this.localShowOverwriteModal = newValue;
    },
  },
};
</script>
