<script>

export default {
  props: {
    column: String,
    label: String,
    sortable: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data() {
    return {
      sortDirection: 'asc',
      active: false,
      filterActive: false,
      currentFilters: {},
      filterOpen: false,
      href: '',
      filterOptions: {}
    };
  },
  methods: {
    updateFilters() {
      const currentQuery = new URLSearchParams(window.location.search);

      //Remove any empty filters
      for (const key in this.currentFilters) {
        if (this.currentFilters[key] === '') {
          delete this.currentFilters[key];
        }
      }
      currentQuery.set('filters', JSON.stringify(this.currentFilters));

      window.location.href = `${window.location.pathname}?${currentQuery.toString()}`;
    }
  },
  mounted() {
    const currentQuery = new URLSearchParams(window.location.search);
    this.currentFilters = currentQuery.get('filters') ? JSON.parse(currentQuery.get('filters')) : {};

    if (currentQuery.get('sort_column') === this.column) {
      this.sortDirection = currentQuery.get('sort_direction');
      this.active = true;
    }

    currentQuery.set('sort_column', this.column);
    currentQuery.set('sort_direction', this.sortDirection === 'desc' ? 'asc' : 'desc');
    //Remove any empty filters
    for (const key in this.currentFilters) {
      if (this.currentFilters[key] === '') {
        delete this.currentFilters[key];
      }
    }
    if (this.currentFilters) {
      currentQuery.set('filters', JSON.stringify(this.currentFilters));
    }

    this.filterActive = this.currentFilters[this.column] !== undefined;

    this.href = `${window.location.pathname}?${currentQuery.toString()}`;

  }
};
</script>
<template>
  <div class="h-100">
    <div class="d-flex h-100 justify-content-between align-items-center font-weight-light">
      <span>{{ label }}</span>
      <div>
        <template v-if="filterable">
          <a class="text-decoration-none cursor-pointer text-ci-secondary-contrast"
             @click="filterOpen = !filterOpen">
            <i :class="{
            'fal': !filterActive,
            'fas': filterActive
          }" class="fa-fw fa-filter"></i>
          </a>
        </template>
        <a v-if="sortable" :href="href"
           class="text-decoration-none cursor-pointer text-ci-secondary-contrast">
          <i :class="{
          'fa-sort-amount-up': active && sortDirection === 'asc',
          'fa-sort-amount-down': active && sortDirection === 'desc',
          'fa-sort-alt': !active,
        }" class="fal fa-fw"></i>
        </a>
      </div>
    </div>

    <template v-if="filterable && filterOpen">
      <b-form class="p-2 border shadow-sm position-absolute filter-box bg-white" @submit.prevent="updateFilters">
        <b-input-group>
          <template v-if="type === 'boolean'">
            <b-form-checkbox v-model="currentFilters[column]" class="rounded-0"></b-form-checkbox>
          </template>
          <template v-else-if="type === 'date'">
            <b-form-datepicker v-model="currentFilters[column]" class="rounded-0"></b-form-datepicker>
          </template>
          <template v-else>
            <b-form-input v-model="currentFilters[column]" class="rounded-0" placeholder="Filter"></b-form-input>
          </template>

          <!-- small button to remove filter -->
          <b-button v-if="filterActive" class="rounded-0" size="sm" variant="ci-secondary"
                    @click="currentFilters[column] = ''">
            <i class="fal fa-fw fa-times"></i>
          </b-button>
          <!-- small button to apply filter -->
          <b-button class="rounded-0" size="sm" variant="ci-primary" @click="updateFilters">
            <i class="fal fa-fw fa-check"></i>
          </b-button>
        </b-input-group>
      </b-form>
    </template>

  </div>
</template>

<style scoped>
.filter-box {
  top: 100%;
  left: 0;
  width: max-content;
}
</style>
