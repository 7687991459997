<template>
  <div class="w-100">
    <b-form-group>
      <div v-if="value && this.param.element === 'param-image-upload'">
        <div v-if="!this.multiple" class="align-items-center">
          <b-img
             :src="imgShowSrc"
             class="image"
             :style="{maxHeight: maxHeight + 'px' }"
             @click="openImageModal"
          />
        </div>
        <div v-else class="h-100 w-100" @click="openImageModal">
          <b-carousel
            v-model="carouselIndex"
            ref="myCarousel"
            class="myCarousel myImageCarousel zoom-cursor w-100"
            :style="{maxHeight: maxHeight + 'px' }"
            id="carousel"
            :controls="this.fileNameArray.length>1"
            :fade="carouselFade"
            :interval="carouselPaused ? 0 : carouselInterval"
          >
            <b-carousel-slide
              class="w-100 h-100 custom-carousel-slide"
              :style="{maxHeight: maxHeight + 'px' }"
              v-for="(sourceValue, index) in imgShowArray" :key="index"
            >
              <div class="image-container h-100">
                <b-img :src="sourceValue" :style="{maxHeight: maxHeight + 'px', maxWidth: '100%' }" class="carousel-image h-100 w-auto"></b-img>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>

        <image-modal
          :fileName="fileName"
          :img-url="imgShowSrc"
          :show-image-modal="showImageModal"
          :multiple="this.multiple"
          :file-name-array="this.fileNameArray"
          :img-array="this.imgShowArray"
          :carousel-index="this.carouselIndex"
          :carousel-fade="carouselFade"
          @close="hideImageModal"
          @close-multi="hideMultiImageModal"
        />
      </div>
      <div v-else-if="value && this.param.element === 'param-file-upload'" class="w-100">
        <div v-if="!this.multiple" class="w-100">
          <b-carousel class="myCarousel" background="#FFFFFF" :interval="carouselPaused ? 0 : carouselInterval" :fade="carouselFade">
            <b-carousel-slide class="w-100 h-100">
              <div class="file-upload-text download-cursor" @click="downloadFile">
                <i
                  v-if="value.endsWith('docx')"
                  class="fal fa-2x fa-file-word mr-2 file-icon"
                ></i>
                <i
                  v-else-if="value.endsWith('png')||value.endsWith('jpg')||value.endsWith('jpeg')||value.endsWith('gif')"
                  class="fal fa-2x fa-image mr-2 file-icon"
                ></i>
                <i
                  v-else-if="value.endsWith('pdf')"
                  class="fal fa-2x fa-file-pdf mr-2 file-icon"
                ></i>
                <i
                  v-else-if="value.endsWith('zip')"
                  class="fal fa-2x fa-file-archive mr-2 file-icon"
                ></i>
                <i
                  v-else-if="value.endsWith('xlsx')"
                  class="fal fa-2x fa-file-excel mr-2 file-icon"
                ></i>
                <i
                  v-else-if="value.endsWith('stp')"
                  class="fal fa-2x fa-file-cube mr-2 file-icon"
                ></i>
                <i v-else class="fal fa-2x fa-file mr-2 file-icon"></i>
                <span class="file-name"> {{ fileName }}</span>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
        <div v-else class="w-100">
          <b-carousel
            background="#FFFFFF"
            v-model="carouselIndex"
            ref="myCarousel"
            class="myCarousel"
            :fade="carouselFade"
            id="carousel"
            :controls="this.fileNameArray.length>1"
            :interval="carouselPaused ? 0 : carouselInterval"
          >
            <b-carousel-slide class="w-100 h-100" v-for="(fileName, index) in fileNameArray" :key="index">
              <div class="file-upload-text download-cursor" @click="multiDownloadSingle">
                <i
                  v-if="fileName.endsWith('docx')"
                  class="fal fa-2x fa-file-word mr-2 file-icon"
                ></i>
                <i
                  v-else-if="fileName.endsWith('png')||fileName.endsWith('jpg')||fileName.endsWith('jpeg')||fileName.endsWith('gif')"
                  class="fal fa-2x fa-image mr-2 file-icon"
                ></i>
                <i
                  v-else-if="fileName.endsWith('pdf')"
                  class="fal fa-2x fa-file-pdf mr-2 file-icon"
                ></i>
                <i
                  v-else-if="fileName.endsWith('zip')"
                  class="fal fa-2x fa-file-archive mr-2 file-icon"
                ></i>
                <i
                  v-else-if="fileName.endsWith('xlsx')"
                  class="fal fa-2x fa-file-excel mr-2 file-icon"
                ></i>
                <i
                  v-else-if="fileName.endsWith('stp')"
                  class="fal fa-2x fa-file-cube mr-2 file-icon"
                ></i>
                <i v-else class="fal fa-2x fa-file mr-2 file-icon"></i>
                <span class="file-name"> {{ fileName }}</span>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
      <b-input-group>
        <template #prepend v-if="value">
          <b-dropdown
            variant="outline-dark"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <i class="fas fa-bars"></i>
            </template>
            <div v-if="!multiple">
              <b-dropdown-item @click="downloadFile">
                <i class="fas fa-download"></i> {{ __('database.order.download')}}
              </b-dropdown-item>
              <b-dropdown-item @click="initiateRenameFile">
                <i class="fas fa-pencil"></i> {{ __('database.order.rename')}}
              </b-dropdown-item>
              <b-dropdown-item @click="openDeleteModal" variant="danger">
                <i class="fas fa-trash-alt"></i> {{ __('database.order.delete')}}
              </b-dropdown-item>
            </div>
            <div v-else>
              <div v-if="fileNameArray.length>1">
                <b-dropdown-header>{{ __('database.order.multifile_dropdownheader_all')}}:</b-dropdown-header>
                <b-dropdown-item @click="multiDownloadAll">
                  <i class="fas fa-download"></i> {{ __('database.order.multifile_download_all')}}
                </b-dropdown-item>
                <b-dropdown-item @click="triggerAddInput">
                  <i class="fas fa-plus"></i> {{ __('database.order.multifile_add')}}
                </b-dropdown-item>
                <input type="file" ref="addFileInput" style="display: none" @change="handleFileInput" multiple>
                <b-dropdown-item @click="triggerOverwriteFileInput" variant="danger">
                  <i class="fas fa-upload"></i> {{ __('database.order.multifile_overwrite_all')}}
                </b-dropdown-item>
                <input type="file" ref="overwriteFileInput" style="display: none" @change="openMultiOverwriteModal" multiple>
                <b-dropdown-item @click="openMultiDeleteModalAll" variant="danger">
                  <i class="fas fa-trash-alt"></i> {{ __('database.order.multifile_delete_all')}}
                </b-dropdown-item>
                <b-dropdown-header>{{ __('database.order.multifile_dropdownheader_selectedfile')}}:</b-dropdown-header>
                <b-dropdown-item @click="multiDownloadSingle">
                  <i class="fas fa-download"></i> {{ __('database.order.download')}}
                </b-dropdown-item>
                <b-dropdown-item @click="initiateRenameFile">
                  <i class="fas fa-pencil"></i> {{ __('database.order.rename')}}
                </b-dropdown-item>
                <b-dropdown-item @click="openMultiDeleteModalSingle" variant="danger">
                  <i class="fas fa-trash-alt"></i> {{ __('database.order.multifile_delete_selected')}}
                </b-dropdown-item>
              </div>
              <div v-else>
                <b-dropdown-item @click="multiDownloadSingle">
                  <i class="fas fa-download"></i> {{ __('database.order.download')}}
                </b-dropdown-item>
                <b-dropdown-item @click="triggerAddInput">
                  <i class="fas fa-plus"></i> {{ __('database.order.multifile_add')}}
                </b-dropdown-item>
                <input type="file" ref="addFileInput" style="display: none" @change="handleFileInput" multiple>
                <b-dropdown-item @click="initiateRenameFile">
                  <i class="fas fa-pencil"></i> {{ __('database.order.rename')}}
                </b-dropdown-item>
                <b-dropdown-item @click="openMultiDeleteModalSingle" variant="danger">
                  <i class="fas fa-trash-alt"></i> {{ __('database.order.delete')}}
                </b-dropdown-item>
              </div>
            </div>
          </b-dropdown>
        </template>
        <b-form-file
          ref="file-input"
          :placeholder="placeholderText"
          @change="handleFileInput"
          :multiple="multiple"
          :browse-text="multiple && fileNameArray.length>0 ? 'Add' : 'Browse'"
          :disabled="busy"
          :accept="allowedDatatypes"
        >
        </b-form-file>
      </b-input-group>
    </b-form-group>
    <delete-modal
      @delete-all="deleteAll"
      @multi-delete-one="multiDeleteOne"
      @close="closeDeleteModal"
      :show-delete-modal="this.showDeleteModal"
      :multiple="multiple"
      :multi-compute-all="multiComputeAll"
      ref="deleteModal"
    />
    <rename-then-upload-modal
      @file-rename-then-upload="renameThenUploadFile"
      @close="closeRenameThenUploadModal"
      :show-rename-then-upload-modal="this.showRenameThenUploadModal"
      :doesFileNameExist="this.doesFileNameExist"
      :folder="this.rootFolderName"
      :oldFileName="this.oldFileName"
      ref="renameThenUploadModal"
    />
    <rename-modal
      @file-rename="renameFile"
      @close="closeRenameModal"
      :show-rename-modal="this.showRenameModal"
      :doesFileNameExist="this.doesFileNameExist"
      :lfmSelectDirectory="this.lfmSelectDirectory"
      :folder="!this.multiple ? this.rootFolderName : this.configText"
      :oldFileName="this.oldFileName"
      ref="renameModal"
    />
    <overwrite-modal
      @file-overwrite="overwriteFile"
      @multi-overwrite="multiOverwrite"
      @close="closeOverwriteModal"
      :show-overwrite-modal="this.showOverwriteModal"
      :multiple="multiple"
      ref="overwriteModal"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import POST from "../../../../../../node_modules/laravel-file-manager/src/http/post";
import GET from "../../../../../../node_modules/laravel-file-manager/src/http/get";
import ImageModal from "./ImageModal.vue";
import DeleteModal from "./DeleteModal.vue";
import RenameThenUploadModal from "./RenameThenUploadModal.vue";
import RenameModal from "./RenameModal.vue"
import OverwriteModal from "./OverwriteModal.vue"
import { mapGetters } from "vuex";

export default {
  components: {OverwriteModal, ImageModal, DeleteModal, RenameThenUploadModal, RenameModal },
  props: {
    value: {
      required: true,
      default: null,
    },
    configText:{
      required: true,
      default: null,
    },
    activeUnit: {
      required: true,
      type: Number,
      default: -1,
    },
    activeParam: {
      required: true,
      type: Number,
      default: -1,
    },
    basePointer: {
      type: Object,
      required: true,
      default: null,
    },
    configNeedle: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    isui: {
      required: false,
      type: Boolean,
      default: false,
    },
    UiNeedle: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    param: {
      required: true,
      type: Object,
      default: null,
    },
    driver: {
      required: true,
      type: Object,
      default: () => {
        return { show: false, groupIndex: -1 };
      },
    },
    configAttribs: {
      required: true,
      type: Object,
      default: null,
    },
    configFileSettings: {
      required: true,
      type: Object,
      default: null,
    },
    translation: {
      required: true,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedFiles: [],
      rootFolderName: "parameter_uploads",
      fileDisk: "order",
      fileOverwrite: 0, //necessary for fileUpload in FileManager (shouldn't be changed in code)
      separator: "|",

      showImageModal: false,
      showDeleteModal: false,
      showOverwriteModal: false,
      showRenameThenUploadModal: false,
      showRenameModal: false,

      oldFileName: "",

      multiBaseFolderName: "Multifiles-00000",
      carouselIndex: 0,
      carouselPaused: false,
      busy: false,
      invalidFeedback: null,
      multiComputeAll: true,

      multiple: this.configFileSettings.multiple,
      carouselFade: this.configFileSettings.carousel_fade,
      carouselInterval: parseInt(this.configFileSettings.carousel_interval),
      allowedDatatypes: this.configFileSettings.allowed_datatypes,
      maxHeight: this.configFileSettings.maxHeight,
    };
  },

  methods: {
    ...mapActions(["saveParam"]),

    async createMultipleFolder(){
      this.busy = true;
      await this.lfmSelectDirectory(this.rootFolderName);
      await this.lfmSortNamesDown();
      if (this.directories.length > 0){
        let Name = this.directories[0].basename; // gives folder name with highest number (due to sorting)
        Name = this.getNextFreeFolderName(Name);
        await this.lfmCreateMultiFileDirectory(Name);
        this.busy = false;
        return this.rootFolderName + "/" + Name;
      } else {
        await this.lfmCreateMultiFileDirectory(this.multiBaseFolderName);
        return this.rootFolderName + "/" + this.multiBaseFolderName;
      }
    },

    getNextFreeFolderName(name){
      let directoryExists;
      do {
        name = this.computeNextFolderName(name);
        directoryExists = this.directoryExist(name);
      } while(directoryExists);
      return name;
    },

    computeNextFolderName(oldName){
      const match = oldName.match(/-(\d+)/);
      if (match){
        let number = parseInt(match[1], 10);
        number ++;
        return oldName.replace(/\d+$/, String(number).padStart(match[1].length, "0"));
      } else{
        return null;
      }
    },

    // save Value of parameter to json-structure
    async submitParam(fileString, folderString = null) {
      this.busy = true;
      if (!this.isui && this.value !== fileString) {
        const paramConfigNeedle = this.configNeedle.concat([
          [this.activeUnit, this.activeParam],
        ]);
        const paramUiMapPointer = this.$calcUiPointer(
          this.basePointer.unitLabel,
          this.UiNeedle
        );

        const upData = {
          basePointer: this.basePointer.unitLabel,
          mastervariable: this.param.mastervariable,
          configNeedle: paramConfigNeedle,
          uiMapPointer: paramUiMapPointer,
          value: fileString,
          text: folderString,
        };
        const response = await this.saveParam(upData);
        if (response) {
          this.invalidFeedback = response;
        }
      }
      this.busy = false;
    },

    async handleFileInput(event) {
      if (event.type === "change") {
        this.selectedFiles = Array.from(event.target.files);
      } else if (event.type === "drop") {
        this.selectedFiles = Array.from(event.dataTransfer.files);
      }
      if (!this.multiple) {
        if (this.value) {
          this.openOverwriteModal();
        } else {
          await this.checkFileNameAndUpload();
        }
      } else {
        if (this.value) {
          // If there are already files inside this parameter, this function only adds new Files to them
          this.multiCheckFileNameAndUpload();
        } else {
          let path = await this.createMultipleFolder();
          await this.uploadSelectedFiles(this.selectedFiles, path);
        }
      }
    },

    async checkFileNameAndUpload(){ // Function for SingleFileUpload
      const fileExists = await this.doesFileNameExist(
        this.rootFolderName,
        this.selectedFiles[0].name
      );

      if (fileExists) {
        this.oldFileName = this.selectedFiles[0].name;
        this.openRenameThenUploadModal(); // Upload happens according to User inputs in modal
      } else {
        await this.uploadSelectedFiles(this.selectedFiles, this.rootFolderName);
      }
    },

    renameThenUploadFile() {
      let renamedFile = new File(
        [this.selectedFiles[0]],
        this.$refs.renameThenUploadModal.newFileName,
        {
          type: this.selectedFiles[0].type,
        }
      );

      this.selectedFiles = this.selectedFiles.slice(1).concat(renamedFile); // silces the existing selectedFile and adds the renamed one.
      this.uploadSelectedFiles(this.selectedFiles, this.rootFolderName);

      this.$bvModal.hide("renameThenUploadModal");
      this.oldFileName = "";
    },

    async multiCheckFileNameAndUpload() {
      let filesToUpload = [];
      let multiAddIgnoredFiles = [];

      for (let i = 0; i < this.selectedFiles.length; i++) {
        const fileExists = await this.doesFileNameExist(
          this.configText,
          this.selectedFiles[i].name
        );

      if (fileExists) {
        multiAddIgnoredFiles.push(this.selectedFiles[i].name)
        } else {
          filesToUpload.push(this.selectedFiles[i]);
        }
      }

      if (filesToUpload.length > 0) {
        await this.uploadSelectedFiles(filesToUpload, this.configText, true);
      }
      if (multiAddIgnoredFiles.length > 0){
        const ignoredFilesText = multiAddIgnoredFiles.join(", <br>")
        this.makeIgnoredFilesToast(ignoredFilesText)
      }
    },

    makeIgnoredFilesToast(ignoredFilesText, append = false) {
      const h = this.$createElement;
      const vNodesMsg = h('p', {domProps: {innerHTML: `${this.__('database.order.fileignoretoast_message')}: <br><b>${ignoredFilesText}</b>`}})
      this.$bvToast.toast([vNodesMsg], {
        title: this.__('database.order.fileignoretoast_title'),
        autoHideDelay: 15000,
        appendToast: append,
        toaster: "b-toaster-top-right",
        variant: "warning",
        solid: true,
        toastClass: "toast-pre-wrap",
      })
    },

    async uploadSelectedFiles(fileArray, path, add=false) {
      // Function works analogue to the upload funtion of the File-Manager in: C:\xampp\htdocs\iso\node_modules\laravel-file-manager\src\store\actions.js
      const data = new FormData();

      data.append("disk", this.fileDisk);
      data.append("path", path);
      data.append("overwrite", this.fileOverwrite);

      for (let i = 0; i < fileArray.length; i += 1) {
        data.append("files[]", fileArray[i]);
      }
      // upload file(s)
      return POST.upload(data).then((response) => {
        if (response.data.result.status === "success") {
          if (!this.multiple) {
            this.submitParam(`${this.rootFolderName}/${fileArray[0].name}`);
            this.resetInputDisplay();
          } else {
            const namesArray = fileArray.map(obj => obj.name);
            let newText = namesArray.join(this.separator);
            if (add){
              newText = this.value + this.separator + newText;
            }
            this.submitParam(newText, path);
            this.resetInputDisplay();

          }
        }
        return response;
      });
    },

    async doesFileNameExist(path, name) {
      await this.lfmSelectDirectory(path);
      return this.fileExist(name);
    },

    async lfmSelectDirectory(path) {
      if(this.$store.state.fm['left'].selectedDirectory === path){
        return true;
      } else {
        await this.$store.dispatch("fm/left/selectDirectory", {
          path: path,
          history: true,
        });
      }
    },

    async lfmSortNamesDown(){
      await this.$store.dispatch('fm/refreshAll');
      await this.$store.dispatch("fm/left/sortBy", {
        field: "name",
        direction: "down",
      });
    },

    async lfmCreateMultiFileDirectory(dirName){
      return POST.createDirectory({
        disk: this.fileDisk,
        path: this.rootFolderName,
        name: dirName,
      }).then((response) => {
        if (response.data.result.status === "success"){
        } else {
        }
      });
    },

    openImageModal() {
      this.showImageModal = true;
      if (this.multiple) {
        this.pauseCarousel();
      }
    },
    hideImageModal() {
      this.showImageModal = false;
      if (this.multiple) {
        this.startCarousel();
      }
    },
    hideMultiImageModal(newIndex){
      this.carouselIndex = newIndex;
      this.showImageModal = false;
    },
    openDeleteModal() {
      this.showDeleteModal = true;
    },
    openMultiDeleteModalAll() {
      this.multiComputeAll = true;
      this.showDeleteModal = true;
    },
    openMultiDeleteModalSingle() {
      this.multiComputeAll = false;
      this.showDeleteModal = true;
      if (this.multiple) {
        this.pauseCarousel();
      }
    },
    closeDeleteModal() {
      this.resetInputDisplay();
      this.showDeleteModal = false;
      if (this.multiple) {
        this.startCarousel();
      }
    },
    openRenameThenUploadModal(index=0) {
      this.showRenameThenUploadModal = true;
    },
    closeRenameThenUploadModal() {
      this.resetInputDisplay();
      this.showRenameThenUploadModal = false;
    },
    openRenameModal() {
      this.showRenameModal = true;
    },
    closeRenameModal() {
      this.resetInputDisplay();
      this.showRenameModal = false;
      if (this.multiple) {
        this.startCarousel();
      }
    },
    openOverwriteModal() {
      this.showOverwriteModal = true;
    },
    openMultiOverwriteModal(event){
      this.selectedFiles = Array.from(event.target.files);
      this.showOverwriteModal = true;
    },
    closeOverwriteModal() {
      this.resetInputDisplay();
      if(this.multiple){
        this.$refs.overwriteFileInput.value = null;
      }
      this.showOverwriteModal = false;
    },


    async initiateRenameFile(){
      if (!this.multiple){
        this.oldFileName = this.fileName;
        this.openRenameModal();
      } else {
        this.pauseCarousel();
        this.oldFileName = this.fileNameArray[this.carouselIndex]
        this.openRenameModal();
      }
    },

    resetInputDisplay(){
      this.$refs['file-input'].reset();
    },

    deleteAll() {
      //functionality from Laravel-File-Manager, adapted in such a way that one can delete files from UI
      let itemsToDelete = null
      if(this.multiple){
        itemsToDelete = [
          {
            path: this.configText,
            type: "dir",
          },
        ];
      } else {
        itemsToDelete = [
          {
            path: this.value,
            type: "file", // 'dir' for directories
          },
        ];
      }
      POST.delete({
        disk: this.fileDisk,
        items: itemsToDelete,
      }).then((response) => {
        if (response.data.result.status === "success") {
          this.submitParam(null);
        } else {
        }
      });
      this.showDeleteModal = false;
    },

    multiDeleteOne() {
      if(this.fileNameArray.length>1){
        const itemsToDelete = [
          {
            path: `${this.configText}/${this.fileNameArray[this.carouselIndex]}`,
            type: "file", // 'dir' for directories
          },
        ];
        //remove the deleted file from the new value
        let tempArray = this.fileNameArray
        tempArray.splice(this.carouselIndex,1)
        const newVal = tempArray.join(this.separator)
        // set carouselIndex to 0 if last element was deleted
        this.carouselIndex = (this.carouselIndex < tempArray.length) ? this.carouselIndex : 0;
        POST.delete({
          disk: this.fileDisk,
          items: itemsToDelete,
        }).then((response) => {
          if (response.data.result.status === "success") {
            this.submitParam(newVal, this.configText);
          } else {
          }
        });
        this.showDeleteModal = false;
        this.resetInputDisplay();
      } else {
        this.deleteAll();
      }
    },

    triggerAddInput() {
      this.$refs.addFileInput.click();
    },

    triggerOverwriteFileInput(){
      this.$refs.overwriteFileInput.click();
    },

    async multiOverwrite(){
      const fileArray = this.value.split(this.separator);
      const itemsToDelete = fileArray.map(item => ({
        path: this.configText + "/" + item,
        type: "file"
      }));

      POST.delete({
        disk: this.fileDisk,
        items: itemsToDelete,
      }).then((response) => {
        if (response.data.result.status === "success") {
        } else {
        }
      });
      await this.uploadSelectedFiles(this.selectedFiles, this.configText);
    },

    downloadFile() {
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.setAttribute("download", this.value);
      GET.download(this.fileDisk, this.value).then((response) => {
        tempLink.href = window.URL.createObjectURL(new Blob([response.data]));
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      });
    },

    multiDownloadSingle() {
      const path = this.configText + "/" + this.fileNameArray[this.carouselIndex];
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.setAttribute("download", path);
      GET.download(this.fileDisk, path).then((response) => {
        tempLink.href = window.URL.createObjectURL(new Blob([response.data]));
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      });
    },

    async multiDownloadAll(){
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      //since folders cannot be downloaded intrinsically via the file manager, we need to zip all files first
      const name = "isobyzahnen-files.zip"
      const zippath = this.configText + "/" + name
      let fileArray = this.value.split(this.separator);
      fileArray = fileArray.map(item => (this.configText + "/" + item));
      POST.zip({
        disk: this.fileDisk,
        path: this.configText,
        name,
        elements: {
          directories: [],
          files: fileArray,}
      }).then((response) => {
        if (response.data.result.status === "success"){
          //now download
          tempLink.setAttribute("download", zippath);
          GET.download(this.fileDisk, zippath).then((response) => {
            tempLink.href = window.URL.createObjectURL(new Blob([response.data]));
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            //then delete zip again
            const itemsToDelete = [
              {
                path: zippath,
                type: "file",
              },
            ];
            POST.delete({
              disk: this.fileDisk,
              items: itemsToDelete,
            }).then((response) => {
              if (response.data.result.status === "success") {
              } else {
              }
            });
          });
        } else {
          console.error("Error zipping the folder: " + response.data.result.message);
        }
      });

    },

    renameFile(){
      let newName = "";
      let oldName = "";
      if (!this.multiple){
        newName =  `${this.rootFolderName}/${this.$refs.renameModal.newFileName}`;
        oldName = `${this.rootFolderName}/${this.oldFileName}`;
        this.$store.dispatch('fm/rename', {
          type: "file",
          newName: newName,
          oldName: oldName,
        })
        this.submitParam(newName);
      } else {
        newName = `${this.configText}/${this.$refs.renameModal.newFileName}`;
        oldName = `${this.configText}/${this.oldFileName}`;
        this.$store.dispatch('fm/rename', {
          type: "file",
          newName: newName,
          oldName: oldName,
        })
        let newArray = this.fileNameArray
        newArray[this.carouselIndex] = this.$refs.renameModal.newFileName;
        const newText = newArray.join(this.separator);
        this.submitParam(newText, this.configText);
      }

      this.$bvModal.hide("renameModal");
      this.resetInputDisplay();
      this.oldFileName = "";
    },

    async overwriteFile(){
      this.deleteAll();
      await this.checkFileNameAndUpload();
    },

    pauseCarousel() {
      this.carouselPaused = true;
    },
    startCarousel() {
      this.carouselPaused = false;
    },
  },
  computed: {
    ...mapGetters("fm/left", [
      "files",
      "directories",
      "filesCount",
      "directoriesCount",
      "fileExist",
      "directoryExist",
    ]), // see functions on github alexusmai laravel file manager under /src/store/manager/getters.js
    ...mapState('fm/left', ["sort"]),

    placeholderText() {
      if(!this.multiple){
        return !this.value ? this.__('database.order.browse_placeholder') : this.fileName;
      } else {
        return !this.value ? this.__('database.order.browse_placeholder') : this.fileNameArray[this.carouselIndex];
      }
    },

    fileName() {
      return !this.value ? null : this.value.substring(this.value.lastIndexOf("/") + 1);
    },
    imgShowSrc(){
      return `/media-pool/preview?disk=${this.fileDisk}&path=${this.value}`;
    },
    fileNameArray(){
      return !this.value ? [] : this.value.split(this.separator);
    },
    imgShowArray(){
      if (this.value){
        return this.fileNameArray.map(name => `/media-pool/preview?disk=${this.fileDisk}&path=${this.configText}/${name}`);
      } else {
        return [];
      }
    },
  },
};
</script>
<style>
.image {
  max-width: 100%;
  width: auto;
  height: auto;
  border-radius: 2px;
  border: 1px solid rgb(0, 0, 0);
  cursor: zoom-in;
}
.myCarousel{
  max-width: 100%;
  border-radius: 2px;
  border: 1px solid rgb(0, 0, 0);
}
.myCarousel .carousel-inner{
  height: 100%;
  width: 100%;
}
.myCarousel .carousel-control-prev,
.myCarousel .carousel-control-next {
  height: 100%;
}

.myCarousel .carousel-inner .carousel-item{
  height: 100%;
  width: 100%;
}

.myImageCarousel{
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  position: relative;
}

.myImageCarousel .carousel-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.image-container{
  max-height: 100%;
  max-width: 100%;
}

.carousel-image{
  object-fit: contain;
}

.custom-carousel-slide .carousel-caption {
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  width: 100%;
}

.custom-carousel-slide{
  align-items: center;
  display: flex;
}

.zoom-cursor{
  cursor: zoom-in;
}
.file-upload-text {
   color: #2E74B5; /* blue textcolor to express a 'download link' */
   font-size: 1rem;
   display: inline-flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100%;
   width: 100%;
   text-align: center;
 }

.myCarousel .carousel-control-prev-icon,
.myCarousel .carousel-control-next-icon {
  background-color: transparent;
  filter: invert(15%) sepia(2%) saturate(300%) hue-rotate(200deg) brightness(50%) contrast(120%);
}
.download-cursor {
  cursor: pointer;
}

.download-cursor:hover .file-icon,
.download-cursor:hover .file-name{
  color: #1B4F72; /* let the link appear in darker blue when hovering*/
}
</style>
