<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
    name="nested-multiple-unit-transition"
  >
    <b-overlay :show="busy" variant="transparent" z-index="1000">
      <div v-if="driver.show" class="d-flex">
        <div
          v-if="showUnitTable == true"
          :class="{
            'flex-grow-1 pr-3': activeUnit === -1,
            'flex-grow-0 pr-15px': activeUnit > -1,
            'h-100 multiple-unit-section-transition': true,
          }"
          @mouseover="tableInFocus = true"
        >
          <div class="d-flex flex-column w-100 h-100">
            <table :id="'table-' + idStub" class="table table-bordered">
              <thead :id="'table-head-' + idStub">
              <tr>
                <th v-if="activeUnit === -1"></th>
                <th
                  v-for="(tableHeader, thIndex) in tableView"
                  :key="thIndex"
                  class="p-2 text-truncate"
                  scope="col"
                >
                  <span>{{ $t("parameters", tableHeader.label) }}</span>
                </th>
                <th class="p-2" width="10%">
                  <div class="d-flex justify-content-center">
                    <b-dropdown
                      v-if="activeUnit == -1"
                      :id="'import-dropdown-' + idStub"
                      v-b-tooltip.hover
                      :title="$t('order', 'import')"
                      class="dropdown-toggle-no-caret mx-1"
                      menu-class="p-2 border border-dark dd-upload-form shadow"
                      no-caret
                      right
                      toggle-class="text-decoration-none"
                      variant="outline-warning"
                    >
                      <template v-slot:button-content>
                        <i class="fas fa-download fa-rotate-180"></i>
                      </template>
                      <b-dropdown-form>
                        <b-form-file
                          :id="'file-input-' + idStub"
                          v-model="inputFile"
                          accept=".json"
                          class="w-100"
                          drop-placeholder="Drop file here..."
                          placeholder="Choose or drop file..."
                        ></b-form-file>
                        <div class="w-100 mt-2 text-right">
                          <b-button
                            v-b-tooltip.hover
                            :disabled="busy"
                            :title="$t('app', 'reset')"
                            variant="outline-dark"
                            @click="resetInputFile"
                          >
                            <i class="fas fa-times"></i>
                          </b-button>
                          <b-button
                            v-b-tooltip.hover
                            :disabled="busy"
                            :title="$t('order', 'import')"
                            variant="outline-warning"
                            @click="importJSON"
                          >
                            <i class="fas fa-check"></i>
                          </b-button>
                        </div>
                      </b-dropdown-form>
                    </b-dropdown>
                    <b-button
                      v-b-tooltip.hover
                      :disabled="busy"
                      :title="$t('order', 'export')"
                      class="mx-1"
                      variant="outline-dark"
                      @click="exportJSON"
                    >
                      <i class="fas fa-upload fa-rotate-180"></i>
                    </b-button>
                    <span
                      v-b-tooltip.hover
                      :title="$t('app', 'create')">
                      <b-button
                        :disabled="busy"
                        class="mx-1"
                        variant="outline-dark"
                        @click="addUnit(-1)"
                      >
                        <i class="fas fa-plus"></i>
                      </b-button>
                      </span>
                  </div>
                </th>
              </tr>
              </thead>

              <tbody v-if="unitConfig.length > 0 && tableView.length > 0">
              <draggable
                :id="'table-draggable-' + idStub"
                :list="unitConfig"
                :move="validateDraggable"
                animation="150"
                class="list-group-multiple"
                ghost-class="ghost"
                handle=".handle"
                @end="checkMove"
              >
                <tr
                  v-for="(cUnit, cindex) in unitConfig"
                  :id="'unit-row-' + idStub + '-' + (+cindex + +offset)"
                  :key="'overview' + '-' + (cindex + +offset)"
                  :class="{
                      'table-secondary': cindex + offset == activeUnit,
                    }"
                >
                  <td
                    v-if="activeUnit === -1"
                    class="handle-column align-middle"
                  >
                    <i
                      v-b-tooltip.hover
                      :title="$t('app', 'reorder')"
                      class="fas fa-grip-lines handle"
                    ></i>
                  </td>
                  <td
                    v-for="(tableCell, tcIndex) in tableView"
                    :key="tcIndex"
                    class="text-truncate align-middle max-w-150px"
                  >
                      <span
                        v-html="
                          unitConfig[cindex][
                            calcIndex(tableCell?.mastervariable)
                          ]?.text
                        "
                      ></span>
                  </td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <b-dropdown
                        :id="'del-unit-' + idStub + '-' + (+cindex + +offset)"
                        v-b-tooltip.hover
                        :disabled="busy"
                        :title="$t('app', 'delete')"
                        class="mx-1"
                        dropleft
                        menu-class="p-0 mr-1 min-w-0 border-0 bg-transparent"
                        no-caret
                        offset="0"
                        variant="outline-warning"
                      >
                        <template v-slot:button-content>
                          <i class="fas fa-times"></i>
                        </template>
                        <b-dropdown-item link-class="p-0 bg-transparent">
                          <b-button
                            :disabled="busy"
                            variant="outline-danger"
                            @click="removeUnit(cindex + offset)"
                          >
                            <i class="far fa-trash-alt"></i>
                          </b-button>
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-button
                        v-b-tooltip.hover
                        :disabled="busy"
                        :title="$t('app', 'edit')"
                        class="mx-1"
                        variant="outline-dark"
                        @click="editUnit(cindex + offset)"
                      >
                        <i class="fas fa-edit"></i>
                      </b-button>
                      <b-button
                        v-b-tooltip.hover
                        :disabled="busy"
                        :title="$t('app', 'duplicate')"
                        class="mx-1"
                        variant="outline-dark"
                        @click="addUnit(cindex + offset)"
                      >
                        <i class="far fa-copy"></i>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </draggable>
              </tbody>
            </table>
            <div
              :class="{
                'w-100': true,
                'd-flex justify-content-between': activeUnit === -1,
              }"
            >
              <div :class="{ 'mb-n1px': activeUnit > -1 }">
                <b-form-select
                  v-model="perPage"
                  :disabled="busy"
                  :options="perPageOptions"
                  class="border"
                ></b-form-select>
              </div>
              <div>
                <b-pagination
                  v-model="currentPage"
                  :align="activeUnit === -1 ? 'right' : 'fill'"
                  :disabled="busy"
                  :limit="activeUnit === -1 ? 5 : 1"
                  :per-page="perPage"
                  :total-rows="totalUnits"
                  class="mb-0"
                  first-text="First"
                  last-text="Last"
                  next-text="Next"
                  prev-text="Prev"
                >
                  <template #first-text
                  ><i class="far fa-chevron-double-left"></i
                  ></template>
                  <template #prev-text
                  ><i class="far fa-chevron-left"></i
                  ></template>
                  <template #next-text
                  ><i class="far fa-chevron-right"></i
                  ></template>
                  <template #last-text
                  ><i class="far fa-chevron-double-right"></i
                  ></template>
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{
            'mh-100 multiple-unit-section-transition bg-white z-900': true,
            'flex-grow-1': activeUnit > -1,
            'highlighted-single-unit shadow': singleUnitHighlighted,
          }"
          @mouseover="tableInFocus = false"
        >
          <div v-if="activeUnit > -1" class="w-100 h-100 border border-dark">
            <nested-single-unit
              :key="idStub + '-' + activeUnit"
              :active-unit="activeUnit"
              :base-pointer="basePointer"
              :config-needle="configNeedle"
              :grid="4"
              :id-stub="idStub + '-' + activeUnit"
              :show-unit-table="showUnitTable"
              :ui-needle="UiNeedle"
              :unit-count="totalUnits"
            />
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <div class="unit-loader-content">
            <div class="unit-loader">
              <div class="unit-loader"></div>
            </div>
          </div>
        </div>
      </template>
    </b-overlay>
  </transition>
</template>

<script>
import {multipleUnit} from "../../../../../mixins/app/multipleUnit";

export default {
  mixins: [multipleUnit],
};
</script>
