<template>
  <b-modal
    :visible.sync="localShowOverwriteModal"
    @hide="$emit('close')"
    centered
    hide-footer
    id="overwriteModal"
    :title="this.__('tenants.file_upload.overwrite')"
  >
   <div v-if="!multiple">
      <p>{{__('tenants.file_upload.overwritemodal_text_single')}}</p>
      <p style="font-size: 12px; color: red;">{{__('tenants.file_upload.delete_warning')}}</p>
      <b-button @click="overwriteFile" variant="outline-danger"
      ><i class="fas fa-arrow-circle-up"></i> {{__('tenants.file_upload.overwrite')}}</b-button
      >
      <b-button @click="closeOverwriteModal">{{__('tenants.file_upload.cancel')}}</b-button>
    </div>
    <div v-else>
      <p>{{__('tenants.file_upload.overwritemodal_text_multi')}}</p>
      <p style="font-size: 12px; color: red;">{{__('tenants.file_upload.delete_warning')}}</p>
      <b-button @click="multiOverwrite" variant="outline-danger"
      ><i class="fas fa-arrow-circle-up"></i> {{__('tenants.file_upload.overwrite')}}</b-button
      >
      <b-button @click="closeOverwriteModal">{{__('tenants.file_upload.cancel')}}</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    showOverwriteModal: Boolean,
    multiple: Boolean,
  },
  data() {
    return {
      localShowOverwriteModal: false,
    };
  },
  computed: {},
  methods: {
    closeOverwriteModal() {
      this.$bvModal.hide("overwriteModal");
      this.$emit("close");
    },
    overwriteFile() {
      this.$bvModal.hide("overwriteModal");
      this.$emit("file-overwrite");
    },
    multiOverwrite() {
      this.$bvModal.hide("overwriteModal");
      this.$emit("multi-overwrite");
    }
  },
  watch: {
    showOverwriteModal(newValue) {
      this.localShowOverwriteModal = newValue;
    },
  },
};
</script>
