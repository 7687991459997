import Vue from "vue";
import {ComputeEngine} from "@cortex-js/compute-engine";

import CalcValue from "../../../plugins/app/calc-value";
import MutateValue from "../../../plugins/app/mutate-value";
import CalcTrigger from "../../../plugins/app/calc-trigger";
import MutateQuery from "../../../plugins/app/mutate-query";
import BuildSnakePointer from "../../../plugins/app/build-snake-pointer";
import axios from "axios";
import __ from "../../../plugins/common/translate-all";

Vue.use(CalcValue);
Vue.use(MutateValue);
Vue.use(CalcTrigger);
Vue.use(MutateQuery);
Vue.use(BuildSnakePointer);
Vue.use(__);

let vuePlugins = new Vue();

var _ = require("lodash");

const state = {
    order: {
        info: {},
        config: {},
        custom_attributes: {},
        cats: [],
        map: {},
        snapshots: [],
        generation: {
            isGenerating: false,
            canGenerate: false,
            generations: [],
        },
        validation: {messages: [], isValidating: false, delay: 0},
        calculation: {isCalculating: false, queue: []},
        export: {templates: []},
        import: {},
        complex_props: {
            configNeedle: [],
            uiNeedle: [],
            basePointer: {},
            driver: {show: false, groupIndex: -1},
        },
    },
};

const getters = {
    getMap: (state) => state.order.map,
    getCats: (state) => state.order.cats,
    getInfo: (state) => state.order.info,
    getConfig: (state) => state.order.config,
    getCustomAttributes: (state) => state.order.custom_attributes,
    getSnapshots: (state) => state.order.snapshots,
    getValidation: (state) => state.order.validation,
    getIsValid: (state) => {
        return _.isEmpty(state.order.validation.messages);
    },
    getGeneration: (state) => state.order.generation,
    getValidationMessages: (state) => state.order.validation.messages,
    getIsValidating: (state) => state.order.validation.isValidating,
    getIsGenerating: (state) => state.order.generation.isGenerating,
    getCanGenerate: (state) => state.order.generation.canGenerate,
    getHasGen: (state, getters) => {
        let genUnits = getters.getGen;
        let hasGen = _.findKey(genUnits, function (o) {
            return o;
        });
        return hasGen ? true : false;
    },
    getGenerations: (state) => state.order.generation.generations,
    getGen: (state) => state.order.config.gen,
    getParams: (state) => state.order.config.params,
    getGuide: (state) => state.order.config.params_guide,
    getBaseUnitStructure: (state) => (baseUnitLabel) =>
        state.order.config.params_guide.configunits[baseUnitLabel].structure,
    getUnit: (state) => (basePointer, uiNeedle) => {
        var unit =
            state.order.map[basePointer.catIndex][basePointer.unitIndex][
                "unit_map"
                ];
        if (uiNeedle.length > 0) {
            for (let index = 0; index < uiNeedle.length; index++) {
                var childMapIndex = uiNeedle[index];
                unit = unit["canvas"][childMapIndex]["parametermap"];
            }
        }
        return unit;
    },
    getUnitLabel: (state, getters) => (basePointer, uiNeedle) => {
        if (uiNeedle.length === 0) {
            return getters.getText({
                trans_group: "configunits",
                trans_key: basePointer.unitLabel,
            });
        }

        let label = "";

        var unit =
            state.order.map[basePointer.catIndex][basePointer.unitIndex][
                "unit_map"
                ];
        for (let index = 0; index < uiNeedle.length; index++) {
            var childMapIndex = uiNeedle[index];
            if (index === uiNeedle.length - 1) {
                if (unit.canvas[childMapIndex].hasOwnProperty("content")) {
                    var transGroup =
                        unit.canvas[childMapIndex].content.trans_group;
                    var transKey = unit.canvas[childMapIndex].content.trans_key;
                    label = getters.getText({
                        trans_group: transGroup,
                        trans_key: transKey,
                    });
                }
                label = label
                    ? label
                    : getters.getText({
                        trans_group: "configunits",
                        trans_key: unit.canvas[childMapIndex]["label"],
                    });

                return label;
            }
            unit = unit.canvas[childMapIndex]["parametermap"];
        }
        return label;
    },
    getConfigUnit: (state) => (args) => {
        var basePointer = args.basePointer;
        var configNeedle = args.configNeedle;
        var activeUnit = args.activeUnit;

        var configUnit = state.order.config.params[basePointer.unitLabel];

        if (configNeedle.length > 0) {
            for (let index = 0; index < configNeedle.length; index++) {
                var stepActiveUnit = configNeedle[index][0];
                var stepActiveChild = configNeedle[index][1];
                if (stepActiveUnit > -1) {
                    configUnit = configUnit[stepActiveUnit];
                }
                configUnit = configUnit[stepActiveChild];
                configUnit = configUnit[Object.keys(configUnit)[0]];
            }
        }

        if (activeUnit > -1) {
            configUnit = configUnit[activeUnit];
        }
        return configUnit;
    },
    getUnitcalcValue: (state, getters) => (args) => {
        let unit = args.unit;
        let sameBase = _.isEmpty(unit);

        let uiNeedle = args.uiNeedle;
        let baseUnitLabel = sameBase ? uiNeedle.split("/")[0] : unit;

        let nestedBase = getters.getBaseUnitStructure(baseUnitLabel) === 1;

        if (sameBase && _.isEmpty(args.uiMap) && !nestedBase) {
            return vuePlugins.$calcValue(
                args.mastervariable,
                args.targetConfigUnit
            );
        }

        let uiMap = args.uiMap;
        let mastervariable = args.mastervariable;
        let activeUnit = args.targetActiveUnit;

        let targetConfigNeedle = args.targetConfigNeedle;

        let baseConfigUnit = _.cloneDeep(
            state.order.config.params[baseUnitLabel]
        );

        let gcuArgs = {
            configUnit: baseConfigUnit,
            ui_map: uiMap,
            config_needle: targetConfigNeedle,
            trace_param: sameBase,
            active_unit: activeUnit,
        };

        let configUnit = getters.getConfignitByUiMap(gcuArgs);

        return vuePlugins.$calcValue(mastervariable, configUnit);
    },
    getConfignitByUiMap: (state, getters) => (args) => {
        let configUnit = args.configUnit;
        let activeUnit = args.active_unit;
        let configNeedle = args.config_needle.concat([[activeUnit, 0]]);
        let uiMap = args.ui_map;
        let traceParam = args.trace_param;

        let uiSteps = uiMap.length;

        let covered = configNeedle.length > uiSteps;

        for (let level = 0; level < uiSteps; level++) {
            let coveredStep = configNeedle.length > level;

            let isMultiple = getters.getIsMultiple(configUnit);

            if (isMultiple) {
                if (
                    traceParam &&
                    coveredStep &&
                    configUnit.length > configNeedle[level][0]
                ) {
                    configUnit = configUnit[configNeedle[level][0]];
                } else {
                    configUnit = configUnit[0];
                }
            }

            let jumpIndex = _.findIndex(configUnit, function (el) {
                return el.hasOwnProperty(uiMap[level]);
            });

            configUnit =
                jumpIndex > -1
                    ? configUnit[jumpIndex][uiMap[level]]
                    : configUnit;
        }

        let lastisMultiple = getters.getIsMultiple(configUnit);

        if (lastisMultiple) {
            let lastActiveUnit = !(traceParam && covered)
                ? 0
                : configNeedle[uiSteps][0] > -1
                    ? configNeedle[uiSteps][0]
                    : 0;

            configUnit =
                configUnit.length > lastActiveUnit
                    ? configUnit[lastActiveUnit]
                    : configUnit[0];
        }

        return configUnit;
    },
    mutateDSConfig: (state, getters) => (args) => {
        let dsConfig = _.cloneDeep(args.config);

        let argConfigUnit = args.configUnit;
        let argActiveUnit = args.activeUnit;
        let argConfigNeedle = args.configNeedle;

        if (
            dsConfig.hasOwnProperty("scopes") &&
            _.isEmpty(dsConfig.scopes) === false
        ) {
            for (let scope in dsConfig.scopes) {
                if (
                    _.isEmpty(dsConfig.scopes[scope]) === false &&
                    dsConfig.scopes[scope].hasOwnProperty("config")
                ) {
                    dsConfig.scopes[scope].config = getters.mutateDSConfig({
                        config: dsConfig.scopes[scope].config,
                        configUnit: argConfigUnit,
                        activeUnit: argActiveUnit,
                        configNeedle: argConfigNeedle,
                    });
                }
            }
        }

        if (dsConfig.hasOwnProperty("query") && Array.isArray(dsConfig.query)) {
            let mutatedQuery = [];

            for (let index = 0; index < dsConfig.query.length; index++) {
                const subQuery = dsConfig.query[index];

                mutatedQuery.push({
                    prefix: subQuery.prefix,
                    parameter: subQuery.parameter,
                    operator: subQuery.operator,
                });

                if (!subQuery.indirect) {
                    mutatedQuery[index].reference = subQuery.reference;
                    continue;
                }

                let referenceValue = null;

                let sameTarget =
                    subQuery.ref_unit === null && _.isEmpty(subQuery.ref_uiMap);

                if ("unit" in subQuery && !sameTarget) {
                    referenceValue = getters.getUnitcalcValue({
                        mastervariable: subQuery.reference,
                        unit: subQuery.ref_unit,
                        uiMap: subQuery.ref_uiMap,
                        uiNeedle: subQuery.ref_ui_needle,
                        targetConfigUnit: argConfigUnit,
                        targetConfigNeedle: argConfigNeedle,
                        targetActiveUnit: argActiveUnit,
                    });
                } else {
                    referenceValue = vuePlugins.$calcValue(
                        subQuery.reference,
                        argConfigUnit
                    );
                }

                referenceValue = vuePlugins.$mutateValue(
                    referenceValue,
                    subQuery.mutator,
                    subQuery.argument,
                    subQuery.fallback
                );

                mutatedQuery[index].reference = referenceValue;
            }
            dsConfig.query = mutatedQuery;
        }
        return dsConfig;
    },
    getConfigUnitPaginated: (state, getters) => (args) => {
        const wholeUnit = getters.getConfigUnit(args);
        const unitCount = wholeUnit.length;
        const unitChunk = wholeUnit.slice(
            args.perPage * (args.currentPage - 1),
            args.perPage * args.currentPage
        );
        return {
            totalUnits: unitCount,
            loadedUnits: unitChunk,
        };
    },
    getParamValue: (state, getters) => (args) => {
        var configUnit = getters.getConfigUnit(args);
        return configUnit[args.activeParam]["value"];
    },
    getFileNames: (state, getters) => () => {
        const fileNames = [];
        const traverseParams = (params) => {
            if (Array.isArray(params)) {
                params.forEach(traverseParams);
            } else if (typeof params === 'object' && params !== null) {
                if (params.value && typeof params.value === 'string') {
                    if (params.value.includes('|')) {
                        const basePath = params.text;
                        const files = params.value.split('|');
                        files.forEach(file => fileNames.push(`${basePath}/${file}`));
                    } else if (params.value.startsWith('parameter_uploads/')) {
                        fileNames.push(params.value);
                    }
                }
                Object.values(params).forEach(traverseParams);
            }
        };
        traverseParams(state.order.config.params);
        return fileNames;
    },
    getParamClass: (state, getters) => (args) => {
        var configUnit = getters.getConfigUnit(args);
        return configUnit[args.activeParam]["class"];
    },
    getParamText: (state, getters) => (args) => {
        var configUnit = getters.getConfigUnit(args);
        const retText = configUnit[args.activeParam]["text"];
        return retText !== undefined ? retText : null;
    },
    getParamFeedback:
        (state, getters) =>
            (paramMastervariable, paramBasePointer, paramConfigNeedle) => {
                const validationMessages = state.order.validation.messages;

                const feedbackIndex = _.findIndex(validationMessages, function (o) {
                    return (
                        o.param.mastervariable == paramMastervariable &&
                        o.param.basePointer == paramBasePointer.unitLabel &&
                        _.isEqual(o.param.configNeedle, paramConfigNeedle)
                    );
                });

                if (feedbackIndex > -1) {
                    return validationMessages[feedbackIndex].message[0];
                }
                return null;
            },

    getParamIndex:
        (state, getters) =>
            (element, unitConfig, activeUnit = -1) => {
                var targetConfigUnit = unitConfig;
                if (activeUnit > -1) {
                    targetConfigUnit = targetConfigUnit[activeUnit];
                }
                if (element.hasOwnProperty("uielement_id")) {
                    return -1;
                }
                if (element.hasOwnProperty("configunit_id")) {
                    return _.findIndex(targetConfigUnit, function (o) {
                        return o.hasOwnProperty(element.label);
                    });
                }
                return _.findIndex(targetConfigUnit, function (o) {
                    return o.mastervariable == element.mastervariable;
                });
            },

    getIsUnique: (state, getters) => (args) => {
        var configUnit = getters.getConfigUnit(args);

        for (let index = 0; index < configUnit.length; index++) {
            const sUnit = configUnit[index];
            if (index != args.unitIndex) {
                if (
                    sUnit[args.paramIndex]["value"] ==
                    configUnit[args.unitIndex][args.paramIndex]["value"]
                ) {
                    return false;
                }
            }
        }
        return true;
    },
    getIsMultiple: () => (configUnit) => {
        if (_.isEmpty(configUnit)) {
            return false;
        }

        for (let index = 0; index < configUnit.length; index++) {
            if (!Array.isArray(configUnit[index])) {
                return false;
            }
        }
        return true;
    },

    getIsEmpty: (state, getters) => (configUnit) => {
        if (_.isEmpty(configUnit)) {
            return true;
        }

        if (configUnit.hasOwnProperty("value")) {
            return configUnit.value === null ? true : false;
        }

        let configArray = Array.isArray(configUnit)
            ? configUnit
            : Object.values(configUnit)[0];

        for (let index = 0; index < configArray.length; index++) {
            if (!getters.getIsEmpty(configArray[index])) {
                return false;
            }
        }

        return true;
    },

    getExportTemplates: (state) => state.order.export.templates,
    getImportConfig: (state) => state.order.import,
    getBaseUnitLabel: (state) => (unitId) => {
        for (let cIndex = 0; cIndex < state.order.map.length; cIndex++) {
            const cat = state.order.map[cIndex];

            const uIndex = _.findIndex(cat, function (unit) {
                return unit.unit_id == unitId;
            });

            if (uIndex > -1) {
                return cat[uIndex].unit_label;
            }
        }
        return null;
    },
    getComplexProps: (state) => state.order.complex_props,

    getIsOnCalQ: (state) => (paramConfigNeedle) => {
        return (
            _.findIndex(state.order.calculation.queue, [
                "paramConfigNeedle",
                paramConfigNeedle,
            ]) > -1
        );
    },
    getCalQPos: (state) => (paramConfigNeedle) => {
        return _.findIndex(state.order.calculation.queue, [
            "paramConfigNeedle",
            paramConfigNeedle,
        ]);
    },
    getHasCalcJobs: (state) => {
        return state.order.calculation.queue.length > 0;
    }
};

const actions = {
    async updateFileNames({state, commit, dispatch}, args) {
        let oldName = args.oldName;
        let newName = args.newName;
        const updateParams = (params) => {
            if (Array.isArray(params)) {
                params.forEach(updateParams);
            } else if (typeof params === 'object' && params !== null) {
                if (params.value && typeof params.value === 'string') {
                    if (params.value.includes('|')) {
                        const oldFile = oldName.split('/').pop();
                        const newFile = newName.split('/').pop();
                        const basePath = params.text;
                        const files = params.value.split('|');
                        const updatedFiles = files.map(file => file === oldFile ? newFile : file);
                        params.value = updatedFiles.join('|');
                        params.text = basePath;
                    } else if (params.value === oldName) {
                        params.value = newName;
                        params.text = newName;
                    }
                }
                Object.values(params).forEach(updateParams);
            }
        };
        updateParams(state.order.config.params);
        const orderId = state.order.info.id;
        const response = await axios.patch(
            `/api/orders/${orderId}/params`,
            {params: state.order.config.params}
        );
        return response.data;
    },
    async loadOrder({commit, dispatch}, id) {
        try {
            const response = await axios.get("/api/orders/" + id);
            commit("setOrder", response.data.data);

            let complexProps = {
                configNeedle: [],
                uiNeedle: [],
                basePointer: {},
                driver: {show: false, groupIndex: -1},
            };

            commit("setComplexProps", complexProps);
        } catch (error) {
            console.log(error.response.data.error);
            console.error(
                "An error has occurred while updating this project. Please contact ISO by zahnen support to restore the project with error code 11:",
                error
            );
            return error.response.data.error;
        }
    },
    async loadUnit({state, commit}, args) {
        let orderId = state.order.info.id;
        const response = await axios.get(
            "/api/orders/" + orderId + "/units/" + JSON.stringify(args)
        );
        commit("setUnit", response.data);
        return response.data.value.length;
    },

    loadUnitOptions({state, getters}, args) {
        var retOptions = [];
        var retAttribs = [args.config.overwrites.primary.source_param];

        var unitLabel = getters.getBaseUnitLabel(args.sid);
        if (!unitLabel) {
            return retOptions;
        }

        args.config.overwrites.secondary.forEach((overwrite) => {
            retAttribs.push(overwrite.source_param);
        });

        const multipleUnit = state.order.config.params[unitLabel];

        let synthConfig = _.isEmpty(args.config.query) ? [] : args.config.query;

        const synthParam = {
            triggers: [{props: {active: true}, config: synthConfig}],
        };

        for (let uIndex = 0; uIndex < multipleUnit.length; uIndex++) {
            const singleUnit = multipleUnit[uIndex];
            const trigger = vuePlugins.$calcTrigger(
                synthParam,
                singleUnit,
                [],
                uIndex,
                true
            );

            if (!trigger.show) {
                continue;
            }

            var option = {};

            for (let aIndex = 0; aIndex < retAttribs.length; aIndex++) {
                const attrib = retAttribs[aIndex];
                const param = singleUnit.find(
                    (element) => element.mastervariable === attrib
                );
                option[attrib] = {text: param.text, value: param.value};
            }
            retOptions.push(option);
        }
        return retOptions;
    },

    async loadComplexOptions({state, getters, dispatch}, args) {
        const suid = args.param.triggers[args.driver.groupIndex].datasource.suid;
        let paramConfigUnit = getters.getConfigUnit(args);
        let dsConfig = _.cloneDeep(
            args.param.triggers[args.driver.groupIndex].datasource.config
        );

        dsConfig = getters.mutateDSConfig({
            config: dsConfig,
            configUnit: paramConfigUnit,
            activeUnit: args.activeUnit,
            configNeedle: args.configNeedle,
        });

        // Common options for dispatch
        const dispatchOptions = {
            sid: suid,
            params: args,
            order_id: state.order.info.id,
            config: dsConfig,
        };
        return await dispatch("loadComplexDatasource", dispatchOptions);
    },

    async loadDynamicOptions({state, getters, dispatch}, args) {
        const suid =
            args.param.triggers[args.driver.groupIndex].datasource.suid;

        return await dispatch("loadDynamicDatasource", {
            sid: suid,
            params: args,
            order_id: state.order.info.id,
        });
    },

    async loadDatasource({getters, dispatch}, args) {
        if (!args.param.has_datasource || args.driver.groupIndex == -1) {
            return [];
        }
        let suid = null;
        suid = args.param.triggers[args.driver.groupIndex].datasource.suid;
        let response = await axios.get("/api/datasources/" + suid + "/details");
        return response.data;
    },

    async loadParamOptions({getters, dispatch}, args) {
        if (!args.param.has_datasource || args.driver.groupIndex == -1) {
            return [];
        }
        args.order_id = state.order.info.id;
        let suid = null;
        let retOps = [];

        switch (args.param.triggers[args.driver.groupIndex].datasource.type) {
            case 1:
                retOps =
                    args.param.triggers[args.driver.groupIndex].datasource
                        .literal;
                return retOps;
                break;

            case 2:
                suid =
                    args.param.triggers[args.driver.groupIndex].datasource.suid;
                var qConfig = _.cloneDeep(
                    args.param.triggers[args.driver.groupIndex].datasource
                        .config
                );

                return await dispatch("loadUnitOptions", {
                    sid: suid,
                    config: qConfig,
                });

                break;
            case 3:
                return await dispatch("loadStaticDatasource", args);

                break;
            case 4:
                return await dispatch("loadDynamicOptions", args);

                break;
            case 5:
                return await dispatch("loadComplexOptions", args);

                break;

            default:
                return [];
        }
    },

    async updateGeneration({state, commit}, data) {
        await commit("setGeneration", data.generation);
        await commit("setIsGenerating", data.is_generating);
        const isValid = _.isEmpty(state.order.validation.messages);
        await commit("setCanGenerate", isValid && !data.is_generating);
    },

    async saveGen({state, dispatch, commit}, upData) {
        await axios.patch("/api/orders/" + state.order.info.id + "/gen", {
            gen: upData,
        });
        await commit("setGenUnit", upData);

        dispatch("delayedValidateOrder");
    },

    async startGen({state, getters, commit}) {
        let getHasGen = getters.getHasGen;
        let canGenerate = state.order.generation.canGenerate;
        let isGenerating = state.order.generation.isGenerating;
        if (isGenerating || !canGenerate || !getHasGen) {
            return false;
        }
        try {
            await commit("setCanGenerate", false);
            const response = await axios.post(
                "/api/orders/" + state.order.info.id + "/generations/store"
            );
            await commit("setIsGenerating", true);
            await commit("addGeneration", response.data.data);
            return true;
        } catch (error) {
            if (error.response) {
                await commit(
                    "setValidationMessages",
                    error.response.data.errors
                );
                await commit("setCanGenerate", false);
                return false;
            } else {
                return false;
            }
        }
    },

    async downloadGeneratedFile({state}, args) {
        try {
            const response = await axios({
                url:
                    "/api/orders/" +
                    state.order.info.id +
                    "/generations/" +
                    args.genID +
                    "/" +
                    args.eIndex +
                    "/results/" +
                    args.fileExtension +
                    "/" +
                    args.fileIndex,
                method: "GET",
                responseType: "blob",
            });
            return response.data;
        } catch (error) {
            return false;
        }
    },

    async deleteGeneration({state, commit}, args) {
        try {
            await axios.delete(
                "/api/orders/" + state.order.info.id + "/generations/" + args.id
            );
            await commit("removeGeneration", args.index);
            return true;
        } catch (error) {
            return false;
        }
    },
    async truncateUnit({state, commit}, args) {
        try {
            const response = await axios.patch(
                "/api/orders/" + state.order.info.id + "/truncate-unit",
                {param: args}
            );
            await commit("setUnit", response.data);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    async saveParam({state, commit, dispatch}, args) {
        let orderId = state.order.info.id;
        try {
            const response = await axios.patch(
                "/api/orders/" + orderId + "/param",
                {param: args}
            );

            args.paramIndex = response.data.paramIndex;
            commit("setParamValue", args);
            dispatch("delayedValidateOrder");
        } catch (error) {
            if (error.response) {
                let retErrors = Object.assign({}, error.response.data.errors);
                let errorMessage = retErrors[args.mastervariable][0];

                let validationMessage = {
                    message: [errorMessage],
                    param: args,
                };
                validationMessage.param.snakePointer =
                    vuePlugins.$buildSnakePointer(args);

                await commit("addValidationMessage", validationMessage);
            }
        }
    },

    async updateUnit({state, commit}, patchData) {
        try {
            let orderId = state.order.info.id;
            const response = await axios.patch(
                "/api/orders/" + orderId + "/units",
                patchData
            );
            let params = response.data.updated_params;
            for (let index = 0; index < params.length; ++index) {
                await commit("setParamByPointer", params[index]);
            }
            return null;
        } catch (error) {
            if (error.response) {
                const retErrors = Object.assign({}, error.response.data.errors);
                return retErrors;
            } else {
                return null;
            }
        }
    },

    async changeOrderMultipleUnit({state, getters, commit}, patchData) {
        try {
            let orderId = state.order.info.id;
            const response = await axios.patch(
                "/api/orders/" + orderId + "/reorder-unit",
                {param: patchData}
            );
            if (response.data.result == true) {
                await commit("setUnit", response.data);
            }
        } catch (error) {
            if (error.response) {
                const retErrors = Object.assign({}, error.response.data.errors);
                return retErrors;
            } else {
                return null;
            }
        }
    },

    async newUnit({state, dispatch}, args) {
        await axios.put("/api/orders/" + state.order.info.id + "/units", args);
        return await dispatch("loadUnit", args);
    },

    async deleteUnit({state, dispatch}, args) {
        await axios.delete(
            "/api/orders/" +
            state.order.info.id +
            "/units/" +
            JSON.stringify(args)
        );
        return await dispatch("loadUnit", args);
    },
    async importUnitFlat({state, dispatch}, args) {
        await axios({
            method: "post",
            url: "/api/orders/" + state.order.info.id + "/import-unit-flat",
            data: args.formData,
            headers: {"Content-Type": "multipart/form-data"},
        });
        dispatch("loadUnit", args.unitData);
    },
    async importUnit({state, dispatch}, args) {
        await axios({
            method: "post",
            url: "/api/orders/" + state.order.info.id + "/import-unit",
            data: args.formData,
            headers: {"Content-Type": "multipart/form-data"},
        });
        dispatch("loadUnit", args.unitData);
    },
    async exportUnitFlat({state, dispatch}, args) {
        const response = await axios({
            url:
                "/api/orders/" +
                state.order.info.id +
                "/export-unit-flat/" +
                JSON.stringify(args),
            method: "GET",
            responseType: "blob",
        });
        dispatch("loadUnit", args);
        return response;
    },
    async exportUnit({state, dispatch}, args) {
        const response = await axios({
            url:
                "/api/orders/" +
                state.order.info.id +
                "/export-unit/" +
                JSON.stringify(args),
            method: "GET",
            responseType: "blob",
        });
        dispatch("loadUnit", args);
        return response;
    },
    async exportOrder({state}, exportTemplateId) {
        let template = state.order.export.templates.find(
            (t) => t.id === exportTemplateId
        );
        if (template === undefined) {
            return false;
        }
        let orderId = state.order.info.id;
        try {
            const response = await axios({
                url: "/api/orders/" + orderId + "/export/" + exportTemplateId,
                method: "GET",
                responseType: "blob",
            });
            return response.data;
        } catch (error) {
            return false;
        }
    },

    async ComputeNextParameter({state, getters, commit, dispatch}) {
        if (state.order.calculation.queue.length === 0) {
            commit("setIsCalculating", false);
            return;
        }

        await commit("setIsCalculating", true);

        let args = state.order.calculation.queue.shift();

        if (args === undefined) {
            commit("setIsCalculating", false);
            return;
        }

        let latex = args.activeTrigger.calculation.latex;
        let variableArray = args.activeTrigger.calculation.variableArray;

        for (let index = 0; index < variableArray.length; index++) {
            const varArray = variableArray[index];

            let configUnit = getters.getConfigUnit({
                basePointer: args.basePointer,
                configNeedle: args.configNeedle,
                activeUnit: args.activeUnit,
            });

            let varName = varArray.variable;

            let calcArgs = {
                unit: varArray.unit,
                uiMap: varArray.uiMap,
                uiNeedle: varArray.ui_needle,
                mastervariable: varArray.parameter,
                targetConfigUnit: configUnit,
                targetConfigNeedle: args.configNeedle,
                targetActiveUnit: args.activeUnit,
            };

            let varValue = getters.getUnitcalcValue(calcArgs);

            if (isNaN(varValue)) {
                await commit("setIsCalculating", false);
                dispatch("ComputeNextParameter");
                return;
            }

            varValue = varValue ? varValue : 0;

            latex = latex.replace(varName, varValue);
        }

        const ce = new ComputeEngine();

        let expr = ce.parse(latex);

        let computedValue = expr.N().valueOf();

        computedValue = isNaN(computedValue) ? null : _.ceil(computedValue, 6);

        if (String(computedValue) !== String(args.configValue)) {
            const upData = {
                basePointer: args.basePointer.unitLabel,
                mastervariable: args.mastervariable,
                configNeedle: args.paramConfigNeedle,
                uiMapPointer: args.uiMapPointer,
                value: computedValue,
                text: computedValue,
            };
            await dispatch("saveParam", upData);
        }

        await commit("setIsCalculating", false);

        if (getters.getHasCalcJobs) {
            dispatch("ComputeNextParameter");
        }
    },

    async queuedComputeParameter({state, getters, commit, dispatch}, args) {
        let queuePosition = getters.getCalQPos(args.paramConfigNeedle);

        if (queuePosition === -1) {
            commit("addParamToCalQ", args);
        }

        if (state.order.calculation.isCalculating) {
            return;
        }

        dispatch("ComputeNextParameter");
    },

    async validateOrder({state, commit}) {
        await commit("setIsValidating", true);

        let orderId = state.order.info.id;
        try {
            const response = await axios.get(
                "/api/orders/" + orderId + "/validate"
            );
            await commit("setValidationMessages", response.data);
            if (response.data.length > 0) {
                await commit("setCanGenerate", false);
            } else {
                await commit("setCanGenerate", true);
            }
        } catch (error) {
            console.log(error);
        }
        await commit("setIsValidating", false);
    },

    async delayedValidateOrder({state, dispatch, commit}) {
        await commit("setValidationDelay", 3);

        if (state.order.validation.isValidating) {
            return;
        }

        await commit("setIsValidating", true);

        while (
            state.order.validation.delay > 0 &&
            state.order.validation.isValidating
            ) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            if (state.order.validation.isValidating) {
                state.order.validation.delay--;
            }
        }
        dispatch("validateOrder");
    },

    async updateComplexProps({commit}, data) {
        await commit("setComplexProps", data);
    },
};

const mutations = {
    setOrder: (state, order) => (state.order = order),
    setInfo: (state, info) => (state.order.info = info),
    setConfig: (state, config) => (state.order.config = config),
    setCustomAttributes: (state, customAttributes) =>
        (state.order.custom_attributes = customAttributes),
    setParams: (state, params) => (state.order.config.params = params),
    setUnit: (state, args) => {
        let unitValue = args.value;
        let unitPointer = args.pointer;
        let pointerSteps = unitPointer.split(".");

        let unitPath = pointerSteps.shift();
        unitPath += "." + pointerSteps.shift();

        for (let index = 0; index < pointerSteps.length; index++) {
            let pointerStep = pointerSteps[index];

            isNaN(pointerStep)
                ? (unitPath += "." + pointerStep)
                : (unitPath += "[" + pointerStep + "]");
        }
        _.set(state.order.config, unitPath, unitValue);
    },

    setParamByPointer: (state, args) => {
        let pointerSteps = args.pointer.split(".");

        let paramPath = pointerSteps.shift();
        paramPath += "." + pointerSteps.shift();

        for (let index = 0; index < pointerSteps.length; index++) {
            let pointerStep = pointerSteps[index];

            isNaN(pointerStep)
                ? (paramPath += "." + pointerStep)
                : (paramPath += "[" + pointerStep + "]");
        }

        _.set(state.order.config, paramPath + "[value]", args.param.value);
        _.set(state.order.config, paramPath + "[text]", args.param.text);
        setTimeout(function () {
            const baseClass = "parameter-animated";
            const highlightClass = "parameter-highlight " + baseClass;

            _.set(state.order.config, paramPath + "[class]", highlightClass);

            setTimeout(function () {
                _.set(state.order.config, paramPath + "[class]", baseClass);
            }, 2500);
        }, 500);
    },

    setParamValue: (state, args) => {
        let escortUnit = _.cloneDeep(
            state.order.config.params[args.basePointer]
        );
        let paramPath = "";

        let activeUnit = args.configNeedle.slice(-1)[0][0];
        let activeParam = args.configNeedle.slice(-1)[0][1];
        let unitNeedle = args.configNeedle.slice(0, -1);

        for (let index = 0; index < unitNeedle.length; index++) {
            let stepActiveUnit = unitNeedle[index][0];
            let stepAactiveChild = unitNeedle[index][1];

            if (stepActiveUnit > -1) {
                escortUnit = escortUnit[stepActiveUnit];
                paramPath = paramPath + "[" + stepActiveUnit + "]";
            }

            escortUnit = escortUnit[stepAactiveChild];
            let stepUnitlabel = Object.keys(escortUnit)[0];
            escortUnit = escortUnit[stepUnitlabel];

            paramPath =
                paramPath + "[" + stepAactiveChild + "]." + stepUnitlabel;
        }

        if (activeUnit > -1) {
            paramPath = paramPath + "[" + activeUnit + "]";
        }

        paramPath = paramPath + "[" + activeParam + "]";

        _.set(
            state.order.config.params[args.basePointer],
            paramPath + '["value"]',
            args.value
        );
        _.set(
            state.order.config.params[args.basePointer],
            paramPath + '["text"]',
            args.text
        );
    },

    setGen: (state, gen) => (state.order.config.gen = gen),
    setGenUnit: (state, genUnit) =>
        (state.order.config.gen[genUnit.label] = genUnit.value),
    setCats: (state, cats) => (state.order.cats = cats),
    setComplexProps: (state, newComplexProps) =>
        (state.order.complex_props = newComplexProps),
    setMap: (state, map) => (state.order.map = map),

    setValidationMessages: (state, vMessages) =>
        (state.order.validation.messages = vMessages),
    addValidationMessage: (state, message) =>
        state.order.validation.messages.unshift(message),
    setIsCalculating: (state, isCalculating) =>
        (state.order.calculation.isCalculating = isCalculating),
    addParamToCalQ: (state, args) => {
        state.order.calculation.queue.unshift(args);
    },
    sendToBackOfCalQ: (state, args) => {
        state.order.calculation.queue.splice(args.pos, 1);
        state.order.calculation.queue.push(args.args);
    },
    setIsValidating: (state, vIsValidating) =>
        (state.order.validation.isValidating = vIsValidating),
    setValidationDelay: (state, vDelay) =>
        (state.order.validation.delay = vDelay),

    addGeneration: (state, generation) => {
        if (generation.hasOwnProperty("id")) {
            state.order.generation.generations.unshift(generation);
        }
    },
    removeGeneration: (state, index) => {
        state.order.generation.generations.splice(index, 1);
        if (state.order.generation.generations.length === 0) {
            state.order.generation.isGenerating = false;
        }
    },
    setCanGenerate: (state, value) =>
        (state.order.generation.canGenerate = value),
    setIsGenerating: (state, newIsGenerating) =>
        (state.order.generation.isGenerating = newIsGenerating),
    setGeneration: (state, newGeneration) => {
        const genIndex = state.order.generation.generations.findIndex(
            (gen) => gen.id == newGeneration.id
        );
        if (genIndex > -1) {
            state.order.generation.generations.map((generation) => {
                if (generation.id == newGeneration.id) {
                    generation.idefeedback = newGeneration.idefeedback;
                }
                return generation;
            });
        } else {
            state.order.generation.generations.unshift(newGeneration);
        }
        if (state.order.generation.generations.length === 0) {
            state.order.generation.isGenerating = false;
            state.order.generation.canGenerate = _.isEmpty(
                state.order.validation.messages
            );
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
