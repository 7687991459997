<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
    name="parameter-transition"
  >
    <div v-if="driver.show" :class="gridCSS">
      <div v-if="isui == false" :class="wrapperCSS">
        <label :class="[labelCSS, 'text-truncate d-block', 'w-100']" :for="idStub + '-input'" :title="paramTranslation">
          {{ paramTranslation }}
          <b-button
            v-if="!configTooltip.disabled"
            v-b-popover.hover="configTooltip"
            class="p-0"
            size="sm"
            tabindex="-1"
            variant="link"
          >
            <i class="far fa-info-circle"></i>
          </b-button>
        </label>
        <complex-parameter
          v-if="isComplex"
          :key="idStub + '-complex-param'"
          :active-param="activeParam"
          :active-unit="activeUnit"
          :base-pointer="basePointer"
          :busy.sync="busy"
          :config-attribs="configAttribs"
          :config-needle="configNeedle"
          :config-state="invalidInput.state"
          :driver="driver"
          :input-id="idStub + '-input'"
          :invalid-feedback="invalidInput.message"
          :label="
            getDatasourceTransKey(
              param.triggers[driver.groupIndex].datasource.suid
            )
          "
          :modal-id="idStub + '-modal'"
          :options="configOptions"
          :param="param"
          :param-name="paramTranslation"
          :text="configText"
          :ui-needle="UiNeedle"
          :unit-config="unitConfig"
          :value="configValue"
        ></complex-parameter>
        <simple-has-media-paramter
          v-else-if="simpleHasMedia"
          :active-param="activeParam"
          :active-unit="activeUnit"
          :base-pointer="basePointer"
          :config-attribs="configAttribs"
          :config-needle="configNeedle"
          :disabled="configAttribs.disabled || busy"
          :driver="driver"
          :isui="isui"
          :options="configOptions"
          :param="param"
          :ui-needle="UiNeedle"
        ></simple-has-media-paramter>
        <form-checkbox
          v-else-if="param.element === 'form-checkbox'"
          :active-param="activeParam"
          :active-unit="activeUnit"
          :base-pointer="basePointer"
          :config-attribs="configAttribs"
          :config-needle="configNeedle"
          :disabled="configAttribs.disabled || busy"
          :driver="driver"
          :isui="isui"
          :options="configOptions"
          :param="param"
          :translation="paramTranslation"
          :ui-needle="UiNeedle"
        ></form-checkbox>
        <file-parameter
          v-else-if="
            param.element === 'param-image-upload' ||
            param.element === 'param-file-upload'
          "
          :active-param="activeParam"
          :active-unit="activeUnit"
          :base-pointer="basePointer"
          :config-attribs="configAttribs"
          :config-file-settings="configFileSettings"
          :config-needle="configNeedle"
          :disabled="configAttribs.disabled || busy"
          :driver="driver"
          :isui="isui"
          :configText="configText"
          :value="configValue"
          :options="configOptions"
          :param="param"
          :translation="paramTranslation"
          :ui-needle="UiNeedle"
        ></file-parameter>
        <div v-else class="input-group">
          <div
            v-if="
              param.element !== 'wysiwyg-editor' &&
              !param.element.includes('checkbox') &&
              !param.element.includes('radio')
            "
            class="input-group-prepend"
          >
            <span
              v-b-tooltip.hover
              :title="__('tenants.buttons.delete_value')"
            >
              <b-button
                v-if="hasValidConfigValue"
                :disabled="configAttribs.disabled || busy"
                :variant="
                  invalidInput.state === null ? 'outline-dark' : 'outline-danger'
                "
                class="h-100"
                @click="resetParam"
              >
                <i class="far fa-times"></i>
              </b-button>
            </span>
          </div>
          <component
            :is="param.element"
            :id="idStub + '-input'"
            :class="elementCSS"
            :disabled="configAttribs.disabled || busy"
            :invalid-input="invalidInput"
            :options="configOptions"
            :state="invalidInput.state"
            :value="uiValue"
            v-bind="configAttribs"
            @change="updateParameterValue"
          ></component>
          <b-form-invalid-feedback :id="idStub + '-input' + '-invalid'"
          >{{ invalidInput.message }}
          </b-form-invalid-feedback>
          <b-overlay
            :show="busy"
            blur="0"
            no-center
            no-wrap
            rounded="sm"
            spinner-small
            spinner-variant="primary"
            variant="transparent"
          >
            <template #overlay>
              <div class="text-center pt-2">
                <b-spinner small variant="primary"></b-spinner>
              </div>
            </template>
          </b-overlay>
        </div>
      </div>
      <ui-chart v-else-if="param.element === 'chart'"></ui-chart>

      <image-parameter
        v-else-if="param.element === 'img'"
        :imageUpload="imageUpload"
        v-bind="configAttribs"
      ></image-parameter>

      <component
        :is="param.element"
        v-else-if="param.has_content"
        :class="elementCSS"
        v-bind="configAttribs"
      >
        <span> {{ paramTranslation }} </span>
      </component>

      <component
        :is="param.element"
        v-else
        :class="elementCSS"
        v-bind="configAttribs"
      />
    </div>
  </transition>
</template>
<script>
import {parameter} from "../../../../mixins/app/parameter.js";
import FileParameter from "./FileParameter.vue";
import UiChart from "./UiChart.vue";

export default {
  components: { FileParameter, UiChart },
  mixins: [parameter],
};
</script>
