<template>
    <b-modal
      :visible.sync="localShowImageModal"
      @hide="closeImgModal"
      centered
      size="xl"
      hide-footer
      :title="imageTitle"
    >
      <div v-if="!multiple" class="image-container">
        <b-img :src="imgUrl" class="modal-image" center/>
      </div>
      <b-carousel
        v-else
        v-model="localCarouselIndex"
        ref="myModalCarousel"
        class="myModalCarousel"
        id="modal-carousel"
        :controls="this.imgArray.length>1"
        :fade="carouselFade"
        :interval="0"
      >
        <b-carousel-slide
          class="w-100 h-100 modal-carousel-slide"
          v-for="(sourceValue, index) in imgArray" :key="index"
        >
          <div class="modal-image-container">
            <b-img :src="sourceValue" class="modal-carousel-image h-100 w-100"></b-img>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </b-modal>
</template>

<script>
export default {
    props: {
        imgUrl: String,
        showImageModal: Boolean,
        multiple: Boolean,
        imgArray: Array,
        fileName: String,
        fileNameArray: Array,
        carouselIndex: Number,
        carouselFade: Boolean,
    },
    data() {
        return {
            localShowImageModal: false,
            localCarouselIndex: this.carouselIndex,
        };
    },
    computed: {
      imageTitle(){
        return this.multiple ? this.fileNameArray[this.localCarouselIndex] : this.fileName;
      }
    },
    methods: {
      closeImgModal(){
        if(!this.multiple){
          this.$emit("close");
        } else {
          this.$emit("close-multi", this.localCarouselIndex);

        }
      },
      setCarouselCSS(){
        this.$nextTick(()=>{
          const myCarousel = this.$refs.myModalCarousel;
          if (myCarousel && myCarousel.$el) {
            const innerDiv = myCarousel.$el.querySelector('.carousel-inner');
            // Überprüfen, ob das innere div-Element gefunden wurde
            if (innerDiv) {
              // add w-100 class to the inner div-element of the b-carousel
              innerDiv.classList.add('w-100');
            }
            const controlPrev = myCarousel.$el.querySelector('.carousel-control-prev');
            if (controlPrev) {
              controlPrev.classList.add('h-100');
            }
            const controlNext = myCarousel.$el.querySelector('.carousel-control-next');
            if (controlNext) {
              controlNext.classList.add('h-100');
            }
          }
        });
      },
    },
    watch: {
        showImageModal(newValue) {
            this.localShowImageModal = newValue;
            if (newValue) {
              this.setCarouselCSS();
              this.localCarouselIndex = this.carouselIndex;
            }
        },
    },
};
</script>

<style>
.modal-image {
  max-height: 100%;
  width: auto;
  height: auto;
}

.image-container {
  width: 100%;
  height: 80vh;
}

.myModalCarousel{
  width: 100%;
  height: 80vh;
  border-radius: 2px;
  border: 1px solid rgb(0, 0, 0);
  cursor: default;
}
.myModalCarousel .carousel-inner{
   height: 100%;
   width: 100%;
 }
.myModalCarousel .carousel-inner .carousel-item{
  height: 100%;
  width: 100%;
}
.modal-image-container{
  height: 100%;
  width: 100%;
}

.modal-carousel-slide .carousel-caption {
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  width: 100%;
}

.modal-carousel-image{
  object-fit: contain;
}

.modal-carousel-slide{
  align-items: center;
  display: flex;
}

.zoom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 400px;
  cursor: grab;
}
.myModalCarousel .carousel-control-prev-icon,
.myModalCarousel .carousel-control-next-icon {
  background-color: transparent;
  filter: invert(15%) sepia(2%) saturate(300%) hue-rotate(200deg) brightness(50%) contrast(120%);
}
</style>
