<template>
    <b-modal
        :visible.sync="localShowDeleteModal"
        @hide="$emit('close')"
        centered
        hide-footer
        id="deleteModal"
        :title="__('tenants.file_upload.confirm_delete')"
    >
      <div v-if="!multiple">
        <p>{{ __('tenants.file_upload.delete_text')}}</p>
        <p style="font-size: 12px; color: red;">{{ __('tenants.file_upload.delete_warning') }}</p>
        <b-button @click="deleteAll" variant="danger"
        ><i class="fas fa-trash-alt"></i> {{__('database.order.delete')}}</b-button
        >
        <b-button @click="closeDeleteModal">{{__('tenants.file_upload.cancel')}}</b-button>
      </div>
      <div v-else>
        <div v-if="multiComputeAll">
          <p>{{__('tenants.file_upload.delete_text_multi')}}</p>
          <p style="font-size: 12px; color: red;">{{__('tenants.file_upload.delete_warning')}}</p>
          <b-button @click="deleteAll" variant="danger"
          ><i class="fas fa-trash-alt"></i> {{__('tenants.file_upload.delete')}}</b-button>
          <b-button @click="closeDeleteModal">{{__('tenants.file_upload.cancel')}}</b-button>
        </div>
        <div v-else>
          <p>{{__('database.order.delete_text_one_of_multi')}}</p>
          <p style="font-size: 12px; color: red;">{{__('database.order.delete_warning')}}</p>
          <b-button @click="multiDeleteOne" variant="danger"
          ><i class="fas fa-trash-alt"></i> {{__('tenants.file_upload.delete')}}</b-button>
          <b-button @click="closeDeleteModal">{{__('tenants.file_upload.cancel')}}</b-button>
        </div>
      </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        showDeleteModal: Boolean,
        multiple: Boolean,
        multiComputeAll: Boolean,
    },

    data() {
        return {
            localShowDeleteModal: false,
        };
    },
    computed: {},
    methods: {
        closeDeleteModal() {
            this.$bvModal.hide("deleteModal");
            this.$emit("close");
        },
        deleteAll() {
            this.$bvModal.hide("deleteModal");
            this.$emit("delete-all");
        },
        multiDeleteOne() {
          this.$bvModal.hide("deleteModal");
          this.$emit("multi-delete-one");
        },
    },
    watch: {
        showDeleteModal(newValue) {
            this.localShowDeleteModal = newValue;
        },
    },
};
</script>

<style>
.modal-image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}
</style>
