<template>
  <b-modal
    :visible.sync="localShowRenameThenUploadModal"
    @hide="$emit('close')"
    centered
    hide-footer
    id="renameThenUploadModal"
    :title="this.__('database.order.rename')"
  >
    <b-form @submit.prevent="renameThenUploadFile">
      <p>{{ this.__('database.order.renamenewfile_text') }}</p>
      <b-input-group class="mb-3" label-for="newFileName">
        <b-form-input
          v-model="newBaseName"
          @input="handleInput"
          :state="newNameValid"
          id="newFileName"
          required
        >
        </b-form-input>
        <b-input-group-append :is-text="true"
          >.{{ extension }}</b-input-group-append
        >
      </b-input-group>
      <p><b-spinner v-if="busy" small></b-spinner>
      </p>      <p v-if="!newNameUnique&&!busy" style="color: red">
        {{ this.__('database.order.renamemodal_name_exists') }}
      </p>
      <p v-else-if="!newNameValidChars&&!busy" style="color: red;">
        {{this.__('database.order.renamemodal_invalidchars_text') + ': ' + invalidChars}}
      </p>
      <b-button :disabled="!newNameValid||busy" type="submit" variant="primary"
        >{{ this.__('database.order.upload') }}</b-button
      >
      <b-button @click="cancelRenameThenUpload" variant="secondary">{{ this.__('database.order.cancel') }}</b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  props: {
    showRenameThenUploadModal: Boolean,
    doesFileNameExist: Function,
    folder: String,
    oldFileName: String,
  },
  data() {
    return {
      localShowRenameThenUploadModal: false,
      newFileName: "",
      newNameValid: true,
      newNameUnique: true,
      newNameValidChars: true,
      fileNamePattern: /^[^\\/":*?<>|]+$/, // Regular expression for valid filename characters
      extension: "",
      newBaseName: "",
      busy: false,
      invalidChars: "\\, /, :, *, \", ?, <, >, |",
      checkNameValidityToken: null,
      inputDelayTimer: null
    };
  },
  computed: {},
  methods: {
    cancelRenameThenUpload() {
      this.$bvModal.hide("renameThenUploadModal");
      this.$emit("close");
    },
    handleInput() {
      clearTimeout(this.inputDelayTimer);
      this.busy = true
      this.inputDelayTimer = setTimeout(() => {
        this.checkNameValidity();
      }, 400); // 400ms delay
    },
    async checkNameValidity() {
      if (this.checkNameValidityToken) {
        this.checkNameValidityToken.cancelled = true;
      }
      const token = { cancelled: false };
      this.checkNameValidityToken = token;

      const newName = `${this.newBaseName}.${this.extension}`
      try {
        const [fileNameExists, validChars] = await Promise.all([
          this.doesFileNameExist(this.folder, newName),
          this.fileNamePattern.test(this.newBaseName)
        ]);

        if (token.cancelled) {
          return;
        }

        this.newNameUnique = !fileNameExists;
        this.newNameValidChars = validChars;
        this.newNameValid = this.newNameUnique && this.newNameValidChars;
      } finally {
        if (!token.cancelled) {
          this.busy = false;
        }
      }
    },
    renameThenUploadFile() {
      this.$emit("file-rename-then-upload");
    },
    async setDefaultValue() {
      this.busy = true;
      const lastDotIndex = this.oldFileName.lastIndexOf(".");
      const baseName = this.oldFileName.substring(0, lastDotIndex);
      this.extension = this.oldFileName.substring(lastDotIndex + 1);
      let newVersionNumber = 0

      const result = this.extractVersionNumber(baseName);
      let tempBaseName = "";

      if (isNaN(result.versionNumber)) {
        newVersionNumber = 1
        tempBaseName = baseName;
      } else {
        newVersionNumber = result.versionNumber + 1;
        tempBaseName = baseName.slice(0, result.splitindex);
      }
      while (
        await this.doesFileNameExist(
          this.folder,
          `${tempBaseName}(${newVersionNumber}).${this.extension}`
        )
        ) {
        newVersionNumber++;
      }
      this.newBaseName = `${tempBaseName}(${newVersionNumber})`;
      this.busy = false;
    },
    extractVersionNumber(name) {
      /**
       * takes the file's basename and evaluates its version number in the format "(n)"
       * It returns an object with the versionNumber and the index of "("
       * If the format does not match the "(n)", the returned versionNumber is NaN
       */
      const lastParenthesisIndex = name.lastIndexOf("(");

      if (lastParenthesisIndex !== -1) {
        // extract all signs from last "("
        const versionNumber = name.substring(
          lastParenthesisIndex + 1,
          name.length - 1
        );
        // turn the remaining part into a number
        const versionNumberInt = parseInt(versionNumber, 10);
        // if versionNumber contains other parts than numbers, versionNumberInt = NaN
        return {
          versionNumber: versionNumberInt,
          splitindex: lastParenthesisIndex,
        };
      }

      // If no "(" was found, return also NaN
      return {
        versionNumber: NaN,
        splitindex: null,
      };
    },
  },
  watch: {
    showRenameThenUploadModal(newValue) {
      this.localShowRenameThenUploadModal = newValue;
      if (newValue == true) {
        this.setDefaultValue();
      }
    },
    newBaseName(newValue) {
      this.newFileName = `${newValue}.${this.extension}`;
    },
  },
};
</script>
