<template>
  <div class="w-100">
    <div class="input-group">
      <div class="input-group-prepend">
        <span
          v-b-tooltip.hover
          :title="$t('app', 'reset')"
          variant="outline-dark"
          @click="reset"
        >
          <b-button
            variant="outline-dark"
            @click="reset"
          >
            <i class="far fa-times"></i>
          </b-button>
        </span>
      </div>
      <span
        :id="inputId"
        :class="{
          'form-control': true,
          'text-truncate': true,
          'bg-light': true,
          'is-invalid': configState === false,
        }"
      >
        {{ text }}
      </span>
      <div class="input-group-append">
        <b-button
          v-b-modal="modalId"
          v-b-tooltip.hover
          :title="$t('app', 'edit')"
          variant="outline-dark"
        >
          <i class="far fa-pen"></i>
        </b-button>
      </div>
      <b-form-invalid-feedback>{{ invalidFeedback }}</b-form-invalid-feedback>
    </div>
    <b-modal
      :id="modalId"
      :title="paramName"
      body-class="p-0"
      centered
      no-fade
      scrollable
      size="xxl"
    >
      <div class="row no-gutters">
        <div class="col-12 sticky p-3 bg-white border-bottom shadow-sm d-flex justify-content-between">
          <div :class="{ 'col-4': isComplexDocuments }" class="w-100 p-0">
            <b-input-group class="mb-2">
              <b-form-input
                v-model="searchVal"
                :placeholder="$t('app', 'search')"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-dark" @click="searchVal = ''">
                  <i class="far fa-backspace"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div v-if="isComplexDocuments" class="col-4 w-100 p-0">
            <b-input-group class="mb-2">
              <b-form-input
                v-model="docSearchKey"
                :placeholder="__('tenants.datasource.document_search_placeholder')"
                @change="clearDocumentSearch"
                @keyup.enter="fetchSearchResults"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="isSearching" variant="outline-dark" @click="fetchSearchResults">
                  <b-spinner v-if="isSearching" small></b-spinner>
                  <i v-else class="fas fa-search fa-sm"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <!-- Message when no documents are found -->
            <div v-if="noDocumentsFound" class="mt-2 text-danger">
              {{ __('tenants.datasource.no_documents_message') }}
            </div>
          </div>
        </div>

        <div class="col-12 p-3 position-relative">
          <div class="w-100 position-relative">
            <b-table
              :busy="busy"
              :current-page="currentPage"
              :fields="headers"
              :filter="searchVal"
              :filter-ignored-fields="filterIgnoredFields"
              :items="filteredOptions"
              :per-page="perPage"
              :selectable="selectable"
              :tbody-tr-class="calcRowClass"
              bordered
              hover
              no-border-collapse
              select-mode="single"
              sticky-header
              table-class="mh-600px"
              @filtered="updatePagination"
              @row-selected="selectRow"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #head()="scope">
                <span class="text-wrap">{{ scope.label }}</span>
              </template>
              <template #cell()="data">
                <span
                  v-if="data.value.text != null && data.value.text.length > 100"
                >
                  <span
                    class="text-break"
                    v-html="
                      showFullContent[data.index]
                        ? data.value.text
                        : data.value.text.slice(0, 100) + '...'
                    "
                  ></span>
                  <b-button
                    v-if="data.value.text.length > 100"
                    size="sm"
                    variant="outline-secondary"
                    @click="toggleContent(data.index)"
                  >
                    {{
                      showFullContent[data.index]
                        ? $t("app", "less")
                        : $t("app", "more")
                    }}
                  </b-button>
                </span>
                <span v-else>
                  <span class="text-break" v-html="data.value.text"></span>
                </span>
              </template>
              <template v-if="isComplexMedia" #cell(media)="data">
                <div v-if="data.value.length > 1">
                  <b-carousel
                    v-if="data.value"
                    :interval="4000"
                    controls
                  >
                    <b-carousel-slide
                      v-for="(media, key) in data.value"
                      :key="'datasource_media_slide' + key"
                    >
                      <template #img>
                        <b-img
                          :src="media"
                          blank-src
                          class="d-block img-fluid w-auto mh-250px"
                          thumbnail
                        ></b-img>
                      </template>
                    </b-carousel-slide>
                  </b-carousel>
                </div>
                <div v-else>
                  <b-img
                    :src="data.value[0]"
                    blank-src
                    class="d-block img-fluid w-auto mh-250px"
                    thumbnail
                  ></b-img>
                </div>
              </template>
              <template v-if="isComplexDocuments" #cell(documents)="data">
                <div class="documents-list">
                  <div
                    v-for="document in data.value"
                    :key="document.id"
                    class="document-item"
                  >
                    <media-documents
                      :datasource-id="sourceId"
                      :document="document"
                      :highlighted-documents="highlightedDocuments"
                    ></media-documents>
                  </div>
                </div>
              </template>
            </b-table>
            <div v-if="isSearching" class="table-overlay d-flex justify-content-center align-items-center">
              <b-spinner style="width: 3rem; height: 3rem; color: #2e74b5;"></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 mt-2 d-flex justify-content-between">
          <div>
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              class="border"
            ></b-form-select>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :first-text="__('pagination.first_text')"
              :last-text="__('pagination.last_text')"
              :next-text="__('pagination.next_text')"
              :per-page="perPage"
              :prev-text="__('pagination.prev_text')"
              :total-rows="OptionCount"
              align="right"
              class="mb-0"
            >
              <template #first-text
              ><i class="far fa-chevron-double-left"></i
              ></template>
              <template #prev-text
              ><i class="far fa-chevron-left"></i
              ></template>
              <template #next-text
              ><i class="far fa-chevron-right"></i
              ></template>
              <template #last-text
              ><i class="far fa-chevron-double-right"></i
              ></template>
            </b-pagination>
          </div>
          <b-button variant="outline-primary" @click="$bvModal.hide(modalId)">
            {{ $t("app", "close") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      :id="'overwrite-confirmation-' + modalId"
      size="lg"
      @ok="handleOverwrite"
    >
      <template #modal-title> {{ $t("order", "conflicts") }}</template>
      <p
        v-html="
          $t('order', 'conflict_message', { value: this.value, paramName: this.paramName })
        "
      ></p>
      <div class="d-block text-center">
        <b-container fluid>
          <form ref="form" @submit.stop.prevent="handleOverwrite">
            <b-table
              :fields="tableFields"
              :items="overWriteConflicts"
              hover
              striped
            >
              <template #cell(path)="data">
                {{ data.value }}
              </template>
              <template #cell(oldValue)="data">
                <span v-if="data.value != null && data.value.length > 100">
                  <span
                    class="text-break"
                    v-html="
                      showFullContOverwrite['old'][data.index]
                        ? data.value
                        : data.value.slice(0, 100) + '...'
                    "
                  ></span>
                  <b-button
                    v-if="data.value.length > 100"
                    size="sm"
                    variant="outline-secondary"
                    @click="toggleContentOverwrite(data.index, 'old')"
                  >
                    {{
                      showFullContOverwrite["old"][data.index]
                        ? $t("app", "less")
                        : $t("app", "more")
                    }}
                  </b-button>
                </span>
                <span v-else>
                  <span class="text-break" v-html="data.value"></span>
                </span>
              </template>
              <template #cell(overwriteValue)="data">
                <span v-if="data.value != null && data.value.length > 100">
                  <span
                    class="text-break"
                    v-html="
                      showFullContOverwrite['overwrite'][data.index]
                        ? data.value
                        : data.value.slice(0, 100) + '...'
                    "
                  ></span>
                  <b-button
                    v-if="data.value.length > 100"
                    size="sm"
                    variant="outline-secondary"
                    @click="toggleContentOverwrite(data.index, 'overwrite')"
                  >
                    {{
                      showFullContOverwrite["overwrite"][data.index]
                        ? $t("app", "less")
                        : $t("app", "more")
                    }}
                  </b-button>
                </span>
                <span v-else>
                  <span class="text-break" v-html="data.value"></span>
                </span>
              </template>
              <template #cell(action)="data">
                <div class="d-flex justify-content-center">
                  <b-button
                    v-b-tooltip.hover
                    :class="{
                      'btn-info': data.value === 'keep',
                      'btn-selected': data.value === 'keep',
                    }"
                    :title="__('messages.old_value_title')"
                    class="mr-2"
                    size="sm"
                    @click="keepOldValue(data)"
                  >
                    <i class="far fa-times"></i>
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    :class="{
                      'btn-success': data.value === 'overwrite',
                      'btn-selected': data.value === 'overwrite',
                    }"
                    :title="__('messages.overwrite_title')"
                    size="sm"
                    @click="overwriteWithNewValue(data)"
                  >
                    <i class="fas fa-arrow-right"></i>
                  </b-button>
                </div>
              </template>
              <template #cell(value)="data">
                <span v-if="data.value != null && data.value.length > 100">
                  <span
                    class="text-break"
                    v-html="
                      showFullContOverwrite['value'][data.index]
                        ? data.value
                        : data.value.slice(0, 100) + '...'
                    "
                  ></span>
                  <b-button
                    v-if="data.value.length > 100"
                    size="sm"
                    variant="outline-secondary"
                    @click="toggleContentOverwrite(data.index, 'value')"
                  >
                    {{
                      showFullContOverwrite["value"][data.index]
                        ? $t("app", "less")
                        : $t("app", "more")
                    }}
                  </b-button>
                </span>
                <span v-else>
                  <span class="text-break" v-html="data.value"></span>
                </span>
              </template>
            </b-table>
          </form>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
.table-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.documents-list {
  display: flex;
  flex-wrap: wrap;
}

.document-item {
  width: 25%;
  box-sizing: border-box;
  margin-left: 5px;
}

.carousel-item {
  max-width: 300px;
  max-height: 250px;
  overflow: hidden;
}

.img-thumbnail {
  width: 100%;
  height: 150px;
  margin: 0 auto;
}

.btn-selected {
  background-color: #007bff; /* Change to your desired color */
  color: #fff; /* Change to your desired text color */
}
</style>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      selectable: true,
      searchVal: "",
      filterIgnoredFields: ["media", "documents"],
      currentPage: 1,
      perPage: 50,
      filteredOptionCount: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      showFullContent: [],
      showFullContOverwrite: {
        old: [],
        overwrite: [],
        value: [],
      },
      overWriteConflicts: [],
      tableFields: [
        {key: "path", label: this.$t("order", "param")},
        {key: "oldValue", label: this.$t("order", "old_value")},
        {key: "overwriteValue", label: this.$t("order", "overwrite_value")},
        {key: "action", label: this.$t("config", "action")},
        {key: "value", label: this.$t("config", "new_value")},
      ],
      docSearchKey: "",
      highlightedDocuments: [],
      isSearching: false,
      noDocumentsFound: false,
    };
  },
  props: {
    param: {
      required: true,
      type: Object,
      default: null,
    },
    driver: {
      required: true,
      type: Object,
      default: () => {
        return {show: false, groupIndex: -1};
      },
    },
    value: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
      default: null,
    },
    text: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
      default: null,
    },
    configAttribs: {
      required: true,
      type: Object,
      default: null,
    },
    options: {
      required: true,
      validator: (prop) => Array.isArray(prop) || prop === null,
      default: () => {
        return [];
      },
    },
    inputId: {
      required: true,
      type: String,
      default: "",
    },
    modalId: {
      required: true,
      type: String,
      default: "",
    },
    paramName: {
      required: true,
      type: String,
      default: "",
    },
    configState: {
      required: true,
      validator: (prop) => typeof prop === "boolean" || prop === null,
      default: false,
    },
    invalidFeedback: {
      required: false,
      type: String,
      default: null,
    },
    basePointer: {
      type: Object,
      required: true,
      default: null,
    },
    configNeedle: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    UiNeedle: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    activeUnit: {
      required: true,
      type: Number,
      default: -1,
    },
    activeParam: {
      required: true,
      type: Number,
      default: -1,
    },
    busy: {
      required: true,
      type: Boolean,
      default: false,
    },
    label: {
      required: true,
      type: String,
      default: "",
    },
    unitConfig: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  watch: {
    text: function (newValue) {
      let valueColumn = this.headers[0].key;
      let valueExist = this.options.filter(
        (item) => item[valueColumn].text == newValue
      );
      if (valueExist.length > 0) {
        const newIndex = this.calcOptionIndex(newValue);
        if (newIndex > -1) {
          this.checkOverwriteCondition(newIndex);
        }
      }
    },
  },
  methods: {
    ...mapActions(["saveParam", "updateUnit"]),
    async submitParam(optionIndex, newValue) {
      if (this.value === newValue) {
        return;
      }
      let valueColumn = this.headers[0].key;

      let paramUiMapPointer = this.$calcUiPointer(
        this.basePointer.unitLabel,
        this.UiNeedle
      );
      let newText =
        optionIndex > -1
          ? this.options[optionIndex][valueColumn].text
          : newValue;

      const upData = {
        basePointer: this.basePointer.unitLabel,
        mastervariable: this.param.mastervariable,
        configNeedle: this.paramConfigNeedle,
        uiMapPointer: paramUiMapPointer,
        value: newValue,
        text: newText,
      };
      await this.saveParam(upData);
      if (optionIndex > -1) {
        await this.checkOverwriteCondition(optionIndex);
      }
    },
    translateHeader(header) {
      let translationStub = "parameters";
      if (this.sourceType == 5) {
        translationStub = "datasources_" + this.label;
      }
      let translation = this.$t(translationStub, header);
      return translation;
    },
    async checkOverwriteCondition(optionIndex) {
      this.overWriteConflicts = [];
      if ("confirmation" in this.overwrites) {
        if (this.overwrites.confirmation) {
          if (this.overwrites.secondary.length > 0) {
            for (let i = 0; i < this.overwrites.secondary.length; i++) {
              const element = this.overwrites.secondary[i];
              if (element.target_param) {
                let unit = element.unit;
                let baseUnitLabel = !unit
                  ? element.ui_needle.split("/")[0]
                  : unit;
                let targetConfigUnit = this.unitConfig;
                if (unit) {
                  targetConfigUnit = this.getParams[baseUnitLabel];
                }
                let args = {
                  unit: element.unit,
                  uiMap: element.uiMap,
                  uiNeedle: element.ui_needle,
                  mastervariable: element.target_param,
                  targetConfigUnit: targetConfigUnit,
                  targetConfigNeedle: element.config_needle,
                  targetActiveUnit: -1,
                };

                let paramValue = this.getUnitcalcValue(args);
                let overwriteValue =
                  this.options[optionIndex][element.source_param].text;

                overwriteValue = this.$mutateValue(
                  overwriteValue,
                  element.operator,
                  element.argument,
                  null
                );
                this.overWriteConflicts.push({
                  mastervariable: element.target_param,
                  unit: element.unit,
                  uiMap: element.uiMap,
                  ui_needle: element.ui_needle,
                  oldValue: paramValue,
                  overwriteValue: overwriteValue,
                  value: overwriteValue,
                  path: this.extractPath(element),
                  action: "overwrite",
                  showFullContent: false,
                });
              }
            }
          }
          if (this.overWriteConflicts.length == 0) {
            this.overwriteParams(optionIndex);
          } else {
            this.$bvModal.show("overwrite-confirmation-" + this.modalId);
          }
        } else {
          this.overwriteParams(optionIndex);
        }
      } else {
        this.overwriteParams(optionIndex);
      }
    },
    extractPath(element) {
      if (!element.unit) {
        return element.target_param;
      }
      let messageArray = element.ui_needle.split("/");
      let newMessage = "";
      for (let wIndex = 0; wIndex < messageArray.length - 1; wIndex++) {
        const word = messageArray[wIndex];
        let unitText = this.$t("configunits", word);
        newMessage += " [" + unitText + "] ";
      }
      newMessage += this.$t(
        "parameters",
        messageArray[messageArray.length - 1]
      );
      return newMessage;
    },
    resetOverwriteModal() {
      this.overWriteConflicts = []; // Clear the data
      this.$bvModal.hide("overwrite-confirmation-" + this.modalId); // Hide the modal
    },
    async handleOverwrite() {
      let updateUnitArgs = {
        configNeedle: this.paramConfigNeedle,
        basePointer: this.basePointer.unitLabel,
        activeUnit: this.activeUnit,
        overwrites: this.overWriteConflicts,
      };

      const unitUpdatedReversed = await this.updateUnit(updateUnitArgs);
      this.resetOverwriteModal();
      return !unitUpdatedReversed;
    },
    keepOldValue(data) {
      data.item.value = data.item.oldValue;
      data.item.action = "keep";
    },
    overwriteWithNewValue(data) {
      data.item.value = data.item.overwriteValue;
      data.item.action = "overwrite";
    },
    async overwriteParams(optionIndex) {
      if (this.overwrites.secondary.length > 0) {
        let overwriteGuide = [];
        for (let i = 0; i < this.overwrites.secondary.length; i++) {
          const element = this.overwrites.secondary[i];
          if (element.target_param && this.options.length > 0 && optionIndex !== -1) {
            let overwriteValue =
              this.options[optionIndex][element.source_param]?.text;

            overwriteValue = this.$mutateValue(
              overwriteValue,
              element.operator,
              element.argument,
              null
            );
            overwriteGuide.push({
              mastervariable: element.target_param,
              value: overwriteValue,
              unit: element.unit,
              ui_needle: element.ui_needle,
              uiMap: element.uiMap,
            });
          }
        }

        let updateUnitArgs = {
          configNeedle: this.paramConfigNeedle,
          basePointer: this.basePointer.unitLabel,
          activeUnit: this.activeUnit,
          overwrites: overwriteGuide,
        };

        const unitUpdatedReversed = await this.updateUnit(updateUnitArgs);
        return !unitUpdatedReversed;
      }
      return false;
    },
    reset() {
      this.submitParam(-1, null);
    },
    calcRowClass(item, type) {
      if (!item || type !== "row") return;
      if (item[this.headers[0].key].value === this.value)
        return "table-primary";
    },
    calcOptionIndex(v) {
      let valueColumn = this.headers[0].key;
      return _.findIndex(
        this.options,
        (element) => element[valueColumn].value === v
      );
    },
    async selectRow(items) {
      this.selectable = false;
      //this.$emit("update:busy", true);

      const newValue = items[0][this.headers[0].key].value;
      const newIndex = this.calcOptionIndex(newValue);

      await this.submitParam(newIndex, newValue);

      this.selectable = true;
      this.$bvModal.hide(this.modalId);
      //this.$emit("update:busy", false);
    },
    updatePagination(filteredItems) {
      this.filteredOptionCount = filteredItems.length;
      this.currentPage = 1;
    },
    toggleContent(index) {
      this.$set(this.showFullContent, index, !this.showFullContent[index]);
    },
    toggleContentOverwrite(index, action) {
      this.$set(
        this.showFullContOverwrite[action],
        index,
        !this.showFullContOverwrite[action][index]
      );
    },
    clearDocumentSearch() {
      this.highlightedDocuments = [];
      this.noDocumentsFound = false;
    },
    async fetchSearchResults() {
      const datasourceId = this.sourceId;
      let searchKey = this.docSearchKey.trim();
      if (!searchKey) {
        return;
      }
      this.isSearching = true; // Start searching
      try {
        await axios({
          url: "/api/datasources/" + datasourceId + "/search-in-files",
          method: 'POST',
          data: {
            search_key: searchKey,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              this.highlightedDocuments = response.data.documents;
              this.noDocumentsFound = this.highlightedDocuments.length === 0;
            } else {
              this.noDocumentsFound = true;
            }
            this.isSearching = false;
          })
          .catch((error) => {
            console.error('There was an issue in document search:', error);
          });
      } catch (error) {
        console.error('Failed to fetch search results:', error);
      } finally {
        this.isSearching = false; // End searching
      }
    },
  },

  computed: {
    ...mapGetters(["getDatasourceTransKey", "getUnitcalcValue", "getParams"]),
    paramConfigNeedle() {
      return this.configNeedle.concat([[this.activeUnit, this.activeParam]]);
    },
    sourceId() {
      return this.param.triggers[this.driver.groupIndex].datasource.suid;
    },
    sourceType() {
      return this.param.triggers[this.driver.groupIndex].datasource.type;
    },
    overwrites() {
      return this.param.triggers[this.driver.groupIndex].datasource.config
        .overwrites;
    },
    headers() {
      let primaryHeader = this.overwrites.primary.source_param;

      let headers = [
        {
          key: primaryHeader,
          label: this.translateHeader(primaryHeader),
          sortable: false,
        },
      ];

      for (let i = 0; i < this.overwrites.secondary.length; i++) {
        const element = this.overwrites.secondary[i];
        if (
          !headers.includes(element.source_param) &&
          element.hidden == false
        ) {
          headers.push({
            key: element.source_param,
            label: this.translateHeader(element.source_param),
            sortable: false,
          });
        }
      }

      if (this.isComplexMedia) {
        headers.push({
          key: "media",
          label: this.$t("app", "param_ds_media"),
          thClass: {"w-25": true},
        });
      }

      if (this.isComplexDocuments) {
        headers.push({
          key: "documents",
          label: this.__('tenants.datasource.documents'),
          thClass: {"w-25": true},
        });
      }
      return headers;
    },
    isComplexMedia() {
      return this.param.triggers[this.driver.groupIndex].datasource.show_media;
    },

    isComplexDocuments() {
      return this.param.triggers[this.driver.groupIndex].datasource.show_documents;
    },
    OptionCount() {
      if (this.searchVal) {
        return this.filteredOptionCount;
      }
      return this.options && this.options.hasOwnProperty("length")
        ? this.options.length
        : 1;
    },
    filteredOptions() {
      if (!this.highlightedDocuments.length) return this.options; // Show all options if no filter
      return this.options.filter(option => {
        const documents = option.documents;
        for (const documentId in documents) {
          if (this.highlightedDocuments.includes(documents[documentId].id)) {
            return true; // Return true if at least one document is highlighted
          }
        }
        return false;
      });
    },
  },
};
</script>
