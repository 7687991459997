<template>
  <div class="h-100 ">
    <b-button
      v-b-toggle.order-notification-list
      v-b-tooltip.hover
      :title="__('tenants.notifications.title')"
      class="text-ci-primary-contrast nav-link py-0 h-100"
      variant="link"
    >
        <span
          class="d-flex justify-content-start align-items-center flex-column border-left border-ci-primary-contrast-light px-2 h-100 ">
            <span class="h-2rem mt-1 d-flex align-items-center position-relative">
                <span class="badge notification-badge font-weight-light text-ci-primary-contrast">{{
                    notifications.length
                  }}</span>
                <i class="fal fa-bell nav-icon"></i>
            </span>
            <span
              class="extra-small mt-1 font-weight-lighter max-w-5rem">{{ __('tenants.notifications.title') }}</span>
        </span>
    </b-button>
    <b-sidebar
      id="order-notification-list"
      :title="__('tenants.notifications.title')"
      backdrop
      body-class="gen-list-body"
      header-class="pr-4"
      right
      width="460px"
    >
      <div class="px-3 py-2">
        <div class="w-100 order-body">
          <div class="p-2">
            <div class="w-100 text-right">
              <button
                class="btn btn-outline-dark"
                type="button"
                @click="markAllAsRead"
              >
                <i class="fal fa-fw fa-check-circle"></i>
                {{ __('tenants.notifications.mark_all_as_read') }}
              </button>
            </div>
          </div>
          <div>
            <div
              v-for="notification in notifications"
              :key="notification.id"
              class="p-2"
            >
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                    {{ notification.title }}
                    <span
                      class="align-text-bottom float-right"
                      @click="markAsRead(notification)"
                    ><button
                      id="goto-parameter-0"
                      class="btn p-0 btn-link disabled"
                      type="button"
                    >
                        <i class="fal fa-check-circle text-dark"></i></button
                    ></span>
                  </h5>
                  <p
                    class="
                      card-text
                      py-2
                      border-top border-light
                      font-weight-light
                    "
                  >
                    <span class="text-danger">
                      {{ notification.body }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {};
  },
  props: {
    userUuid: String,
    orderId: Number | String,
  },
  methods: {
    ...mapActions(["loadNotifications"]),
    markAsRead: function (notification) {
      axios
        .get("/api/notifications/" + notification.id)
        .then((res) => {
          {
            var i;
            for (i = this.notifications.length - 1; i >= 0; --i) {
              if (this.notifications[i].id == notification.id) {
                this.notifications.splice(i, 1);
              }
            }
            this.$bvToast.toast(this.__("tenants.notifications.marked_as_read"), {
              title: this.__("tenants.toast.success"),
              variant: "primary",
              solid: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    markAllAsRead: function () {
      axios
        .post("/api/notifications/read-all")
        .then((res) => {
          {
            this.$store.commit("emptyNotification");
            this.$bvToast.toast(this.__("tenants.notifications.all_marked_as_read"), {
              title: this.__("tenants.toast.success"),
              variant: "primary",
              solid: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.loadNotifications(this.orderId);
  },
  created() {
    Echo.private("users." + this.userUuid).notification((notification) => {
      if (
        notification.databaseFlag == true &&
        (this.orderId == null || this.orderId == notification.orderId)
      ) {
        this.$store.commit("addNotification", notification);
      }
    });
  },
  computed: {
    ...mapGetters(["notifications"]),
  },
};
</script>
