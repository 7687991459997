<template>
  <b-modal
    :visible.sync="localShowRenameModal"
    @hide="$emit('close')"
    centered
    hide-footer
    id="renameModal"
    :title="this.__('tenants.file_upload.rename')"
  >
    <b-form @submit.prevent="renameFile">
      <p>{{ this.__('tenants.file_upload.renamemodal_enter_new_name') }}</p>
      <b-input-group class="mb-3" label-for="newFileName">
        <b-form-input
          v-model="newBaseName"
          @input="handleInput"
          :state="newNameValid"
          id="newFileName"
          required
        >
        </b-form-input>
        <b-input-group-append :is-text="true"
        >.{{ extension }}</b-input-group-append
        >
      </b-input-group>
      <p><b-spinner v-if="busy" small></b-spinner>
      </p>
      <p v-if="!newNameUnique&&!busy" style="color: red">
        {{ this.__('tenants.file_upload.renamemodal_name_exists') }}
      </p>
      <p v-else-if="!newNameValidChars&&!busy" style="color: red;">
        {{this.__('tenants.file_upload.renamemodal_invalidchars_text') + ': ' + invalidChars}}
      </p>
      <b-button :disabled="!newNameValid||busy" type="submit" variant="primary"
      >{{ this.__('tenants.file_upload.rename') }}</b-button
      >
      <b-button @click="cancelRename" variant="secondary">{{ this.__('tenants.file_upload.cancel') }}</b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  props: {
    showRenameModal: Boolean,
    doesFileNameExist: Function,
    lfmSelectDirectory: Function,
    folder: String,
    oldFileName: String,
  },
  data() {
    return {
      localShowRenameModal: false,
      newFileName: "",
      newNameValid: false,
      newNameUnique: false,
      newNameValidChars: true,
      fileNamePattern: /^[^\\/":*?<>|]+$/, // Regular expression for valid filename characters
      extension: "",
      newBaseName: "",
      busy: false,
      invalidChars: "\\, /, :, *, \", ?, <, >, |",
      checkNameValidityToken: null,
      inputDelayTimer: null
    };
  },
  computed: {},
  methods: {
    cancelRename() {
      this.$bvModal.hide("renameModal");
      this.$emit("close");
    },
    handleInput() {
      clearTimeout(this.inputDelayTimer);
      this.busy = true
      this.inputDelayTimer = setTimeout(() => {
        this.checkNameValidity();
      }, 400); // 400ms
    },
    async checkNameValidity() {
      if (this.checkNameValidityToken) {
        this.checkNameValidityToken.cancelled = true;
      }
      const token = { cancelled: false };
      this.checkNameValidityToken = token;

      const newName = `${this.newBaseName}.${this.extension}`
      try {
        const [fileNameExists, validChars] = await Promise.all([
          this.doesFileNameExist(this.folder, newName),
          this.fileNamePattern.test(this.newBaseName)
        ]);

        if (token.cancelled) {
          return;
        }

        this.newNameUnique = !fileNameExists;
        this.newNameValidChars = validChars;
        this.newNameValid = this.newNameUnique && this.newNameValidChars;
      } finally {
        if (!token.cancelled) {
          this.busy = false;
        }
      }
    },
    renameFile() {
      this.$emit("file-rename");
      this.newNameValid = false;
    },
    getFileNameParts(){
      const lastDotIndex = this.oldFileName.lastIndexOf(".");
      this.newBaseName = this.oldFileName.substring(0, lastDotIndex);
      this.extension = this.oldFileName.substring(lastDotIndex + 1);
    },
  },
  watch: {
    async showRenameModal(newValue) {
      this.localShowRenameModal = newValue;
      if (newValue === true) {
        this.getFileNameParts();
        await this.lfmSelectDirectory(this.folder);
        await this.checkNameValidity();
      }
    },
    newBaseName(newValue) {
      this.newFileName = `${newValue}.${this.extension}`;
    },
  },
};
</script>
